//import react
import React from "react";
//import get user
import { getUser, UpdateProfile } from "../../api/users";
import { getStatistics, GraphStatistics } from "../../api/statistics";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
	ErrorNotification,
	SuccessNotification,
} from "../../utils/notifications";
import { setAccessToken } from "../../utils/accessToken";

function withRouter(Component) {
	function ComponentWithRouterProp(props) {
		let location = useLocation();
		let navigate = useNavigate();
		let params = useParams();
		return <Component {...props} router={{ location, navigate, params }} />;
	}
	return ComponentWithRouterProp;
}

class Home extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			reload: false,
		};
	}

	componentDidUpdate = () => {
		if (this.state.loading) this.LoadAll();

		if (
			this.state.graphStatistics === undefined ||
			this.state.graphStatistics === null
		) {
			return;
		}

		if (window.$("#line-chart-demo svg").length > 0) {
			window.$("#line-chart-demo").text("");
		}

		var line_chart_demo = window.$("#line-chart-demo");

		var datainfo = [];

		for (var i = 0; i < this.state.graphStatistics.accesses.length; i++) {
			// console.log("this.state.graphStatistics.accesses[i].Date", this.state.graphStatistics.accesses[i].Date, this.state.graphStatistics.accesses[i].AccessesPerDay, this.state.graphStatistics.accesses[i].answersPerDay);
			datainfo.push({
				y: this.state.graphStatistics.accesses[i].Date,
				a: this.state.graphStatistics.accesses[i].AccessesPerDay,
				b: this.state.graphStatistics.accesses[i].answersPerDay,
			});
		}

		var line_chart = window.Morris.Line({
			element: "line-chart-demo",
			data: datainfo,
			xkey: "y",
			ykeys: ["a", "b"],
			labels: ["Accesses", "Answers"],
			xLabels: "day",
			redraw: true,
		});
		// console.log(datainfo)

		line_chart_demo.parent().attr("style", "");
	};

	componentDidMount = () => {
		if (this.state.loading) this.LoadAll();

		this.reloadStatistics();
	};

	componentWillUnmount = () => {
		// console.log("home unmount!")
		this.setState({
			reload: false,
		});
	};

	LoadAll = async () => {
		try {
			var user = await getUser();
			var countStatistics = await getStatistics();
			var graphStatistics = await GraphStatistics();
			// console.log("statistics", countStatistics);
			this.setState({
				loading: false,
				user: user,
				countStatistics: countStatistics,
				graphStatistics: graphStatistics,
				reload: true,
			});
			// console.log("user ->", user)
		} catch (error) {
			console.log("Error.", error);
		}
	};

	reloadStatistics = async () => {
		if (!this.state.reload) {
			return;
		}

		try {
			var countStatistics = await getStatistics();
			var graphStatistics = await GraphStatistics();
			// console.log("statistics", countStatistics);
			this.setState({
				countStatistics: countStatistics,
				graphStatistics: graphStatistics,
			});

			//sleep for 10 seconds
			await new Promise((resolve) => setTimeout(resolve, 10000));

			this.reloadStatistics();

			// console.log("user ->", user)
		} catch (error) {
			console.log("Error.", error);
		}
	};

	render() {
		if (this.state.loading) {
			return (
				<div>
					<p style={{ textAlign: "center" }}>Loading</p>
					<div
						className="d-flex justify-content-center"
						style={{ display: "flex", justifyContent: "center" }}
					>
						<i
							className="fa fa-spinner fa-spin"
							style={{ textAlign: "center", fontSize: 35 }}
						></i>
					</div>
				</div>
			);
		}

		return (
			<React.Fragment>
				<div className="row">
					{/* <!-- Profile Info and Notifications --> */}
					<div className="col-md-6 col-sm-8 clearfix">
						<ul className="user-info pull-left pull-none-xsm">
							{/* <!-- Profile Info --> */}
							<li className="profile-info dropdown">
								{/* <!-- add class "pull-right" if you want to place this from right --> */}

								<a href="#" className="dropdown-toggle" data-toggle="dropdown">
									{/* <img src="assets/images/thumb-1@2x.png" alt="" className="img-circle" width="44" /> */}
									{"Welcome, " + this.state.user.email}
								</a>

								<ul className="dropdown-menu" style={{ display: "none" }}>
									{/* <!-- Reverse Caret --> */}
									<li className="caret"></li>

									{/* <!-- Profile sub-links --> */}
									<li>
										<a href="extra-timeline.html">
											<i className="entypo-user"></i>
											Edit Profile
										</a>
									</li>

									<li>
										<a href="mailbox.html">
											<i className="entypo-mail"></i>
											Inbox
										</a>
									</li>

									<li>
										<a href="extra-calendar.html">
											<i className="entypo-calendar"></i>
											Calendar
										</a>
									</li>

									<li>
										<a href="#">
											<i className="entypo-clipboard"></i>
											Tasks
										</a>
									</li>
								</ul>
							</li>
						</ul>

						<ul
							className="user-info pull-left pull-right-xs pull-none-xsm"
							style={{ display: "none" }}
						>
							{/* <!-- Raw Notifications --> */}
							<li className="notifications dropdown">
								<a
									href="#"
									className="dropdown-toggle"
									data-toggle="dropdown"
									data-hover="dropdown"
									data-close-others="true"
								>
									<i className="entypo-attention"></i>
									<span className="badge badge-info">6</span>
								</a>

								<ul className="dropdown-menu">
									<li className="top">
										<p className="small">
											<a href="#" className="pull-right">
												Mark all Read
											</a>
											You have <strong>3</strong> new notifications.
										</p>
									</li>

									<li>
										<ul className="dropdown-menu-list scroller">
											<li className="unread notification-success">
												<a href="#">
													<i className="entypo-user-add pull-right"></i>

													<span className="line">
														<strong>New user registered</strong>
													</span>

													<span className="line small">30 seconds ago</span>
												</a>
											</li>

											<li className="unread notification-secondary">
												<a href="#">
													<i className="entypo-heart pull-right"></i>

													<span className="line">
														<strong>Someone special liked this</strong>
													</span>

													<span className="line small">2 minutes ago</span>
												</a>
											</li>

											<li className="notification-primary">
												<a href="#">
													<i className="entypo-user pull-right"></i>

													<span className="line">
														<strong>Privacy settings have been changed</strong>
													</span>

													<span className="line small">3 hours ago</span>
												</a>
											</li>

											<li className="notification-danger">
												<a href="#">
													<i className="entypo-cancel-circled pull-right"></i>

													<span className="line">John cancelled the event</span>

													<span className="line small">9 hours ago</span>
												</a>
											</li>

											<li className="notification-info">
												<a href="#">
													<i className="entypo-info pull-right"></i>

													<span className="line">
														The server is status is stable
													</span>

													<span className="line small">
														yesterday at 10:30am
													</span>
												</a>
											</li>

											<li className="notification-warning">
												<a href="#">
													<i className="entypo-rss pull-right"></i>

													<span className="line">
														New comments waiting approval
													</span>

													<span className="line small">last week</span>
												</a>
											</li>
										</ul>
									</li>

									<li className="external">
										<a href="#">View all notifications</a>
									</li>
								</ul>
							</li>

							{/* <!-- Message Notifications --> */}
							<li className="notifications dropdown">
								<a
									href="#"
									className="dropdown-toggle"
									data-toggle="dropdown"
									data-hover="dropdown"
									data-close-others="true"
								>
									<i className="entypo-mail"></i>
									<span className="badge badge-secondary">10</span>
								</a>

								<ul className="dropdown-menu">
									<li>
										<form className="top-dropdown-search">
											<div className="form-group">
												<input
													type="text"
													className="form-control"
													placeholder="Search anything..."
													name="s"
												/>
											</div>
										</form>

										<ul className="dropdown-menu-list scroller">
											<li className="active">
												<a href="#">
													<span className="image pull-right">
														<img
															src="assets/images/thumb-1@2x.png"
															width="44"
															alt=""
															className="img-circle"
														/>
													</span>

													<span className="line">
														<strong>Luc Chartier</strong>- yesterday
													</span>

													<span className="line desc small">
														This ain’t our first item, it is the best of the
														rest.
													</span>
												</a>
											</li>

											<li className="active">
												<a href="#">
													<span className="image pull-right">
														<img
															src="assets/images/thumb-2@2x.png"
															width="44"
															alt=""
															className="img-circle"
														/>
													</span>

													<span className="line">
														<strong>Salma Nyberg</strong>- 2 days ago
													</span>

													<span className="line desc small">
														Oh he decisively impression attachment friendship so
														if everything.
													</span>
												</a>
											</li>

											<li>
												<a href="#">
													<span className="image pull-right">
														<img
															src="assets/images/thumb-3@2x.png"
															width="44"
															alt=""
															className="img-circle"
														/>
													</span>

													<span className="line">
														Hayden Cartwright - a week ago
													</span>

													<span className="line desc small">
														Whose her enjoy chief new young. Felicity if ye
														required likewise so doubtful.
													</span>
												</a>
											</li>

											<li>
												<a href="#">
													<span className="image pull-right">
														<img
															src="assets/images/thumb-4@2x.png"
															width="44"
															alt=""
															className="img-circle"
														/>
													</span>

													<span className="line">
														Sandra Eberhardt - 16 days ago
													</span>

													<span className="line desc small">
														On so attention necessary at by provision otherwise
														existence direction.
													</span>
												</a>
											</li>
										</ul>
									</li>

									<li className="external">
										<a href="mailbox.html">All Messages</a>
									</li>
								</ul>
							</li>

							{/* <!-- Task Notifications --> */}
							<li className="notifications dropdown">
								<a
									href="#"
									className="dropdown-toggle"
									data-toggle="dropdown"
									data-hover="dropdown"
									data-close-others="true"
								>
									<i className="entypo-list"></i>
									<span className="badge badge-warning">1</span>
								</a>

								<ul className="dropdown-menu">
									<li className="top">
										<p>You have 6 pending tasks</p>
									</li>

									<li>
										<ul className="dropdown-menu-list scroller">
											<li>
												<a href="#">
													<span className="task">
														<span className="desc">Procurement</span>
														<span className="percent">27%</span>
													</span>

													<span className="progress">
														<span
															style={{ width: "27%" }}
															className="progress-bar progress-bar-success"
														>
															<span className="sr-only">27% Complete</span>
														</span>
													</span>
												</a>
											</li>
											<li>
												<a href="#">
													<span className="task">
														<span className="desc">App Development</span>
														<span className="percent">83%</span>
													</span>

													<span className="progress progress-striped">
														<span
															style={{ width: "83%" }}
															className="progress-bar progress-bar-danger"
														>
															<span className="sr-only">83% Complete</span>
														</span>
													</span>
												</a>
											</li>
											<li>
												<a href="#">
													<span className="task">
														<span className="desc">HTML Slicing</span>
														<span className="percent">91%</span>
													</span>

													<span className="progress">
														<span
															style={{ width: "91%" }}
															className="progress-bar progress-bar-success"
														>
															<span className="sr-only">91% Complete</span>
														</span>
													</span>
												</a>
											</li>
											<li>
												<a href="#">
													<span className="task">
														<span className="desc">Database Repair</span>
														<span className="percent">12%</span>
													</span>

													<span className="progress progress-striped">
														<span
															style={{ width: "12%" }}
															className="progress-bar progress-bar-warning"
														>
															<span className="sr-only">12% Complete</span>
														</span>
													</span>
												</a>
											</li>
											<li>
												<a href="#">
													<span className="task">
														<span className="desc">Backup Create Progress</span>
														<span className="percent">54%</span>
													</span>

													<span className="progress progress-striped">
														<span
															style={{ width: "54%" }}
															className="progress-bar progress-bar-info"
														>
															<span className="sr-only">54% Complete</span>
														</span>
													</span>
												</a>
											</li>
											<li>
												<a href="#">
													<span className="task">
														<span className="desc">Upgrade Progress</span>
														<span className="percent">17%</span>
													</span>

													<span className="progress progress-striped">
														<span
															style={{ width: "17%" }}
															className="progress-bar progress-bar-important"
														>
															<span className="sr-only">17% Complete</span>
														</span>
													</span>
												</a>
											</li>
										</ul>
									</li>

									<li className="external">
										<a href="#">See all tasks</a>
									</li>
								</ul>
							</li>
						</ul>
					</div>

					{/* <!-- Raw Links --> */}
					<div className="col-md-6 col-sm-4 clearfix hidden-xs">
						<ul className="list-inline links-list pull-right">
							{/* <!-- Language Selector --> */}
							<li
								className="dropdown language-selector"
								style={{ display: "none" }}
							>
								Language: &nbsp;
								<a
									href="#"
									className="dropdown-toggle"
									data-toggle="dropdown"
									data-close-others="true"
								>
									<img
										src="assets/images/flags/flag-uk.png"
										width="16"
										height="16"
									/>
								</a>
								<ul className="dropdown-menu pull-right">
									<li>
										<a href="#">
											<img
												src="assets/images/flags/flag-de.png"
												width="16"
												height="16"
											/>
											<span>Deutsch</span>
										</a>
									</li>
									<li className="active">
										<a href="#">
											<img
												src="assets/images/flags/flag-uk.png"
												width="16"
												height="16"
											/>
											<span>English</span>
										</a>
									</li>
									<li>
										<a href="#">
											<img
												src="assets/images/flags/flag-fr.png"
												width="16"
												height="16"
											/>
											<span>François</span>
										</a>
									</li>
									<li>
										<a href="#">
											<img
												src="assets/images/flags/flag-al.png"
												width="16"
												height="16"
											/>
											<span>Shqip</span>
										</a>
									</li>
									<li>
										<a href="#">
											<img
												src="assets/images/flags/flag-es.png"
												width="16"
												height="16"
											/>
											<span>Español</span>
										</a>
									</li>
								</ul>
							</li>

							<li className="sep" style={{ display: "none" }}></li>

							<li style={{ display: "none" }}>
								<a href="#" data-toggle="chat" data-collapse-sidebar="1">
									<i className="entypo-chat"></i>
									Chat
									<span className="badge badge-success chat-notifications-badge is-hidden">
										0
									</span>
								</a>
							</li>

							<li className="sep" style={{ display: "none" }}></li>

							<li>
								<a
									href="#"
									onClick={() => {
										setAccessToken("");
										localStorage.removeItem("token");
										// this.props.router.navigate("/login");
										window.location.reload(false);
									}}
								>
									Log Out <i className="entypo-logout right"></i>
								</a>
							</li>
						</ul>
					</div>
				</div>
				<div className="row" style={{ marginTop: 25, marginBottom: 25 }}>
					<div className="col-sm-3 col-xs-6">
						<div
							className="tile-stats tile-red"
							style={{ height: 125 }}
							onClick={() => {
								this.props.router.navigate(`/home/Sites`);
							}}
						>
							<div
								className="icon"
								style={{ top: 0, bottom: 0, display: "flex" }}
							>
								<i
									className="fa fa-building"
									style={{ alignSelf: "center", fontSize: 75 }}
								></i>
							</div>
							<div
								className="num"
								data-start="0"
								data-end="83"
								data-postfix=""
								data-duration="1500"
								data-delay="0"
							>
								{this.state.countStatistics?.SiteCount ?? 0}
							</div>

							<h3>Amount of Sites</h3>
							{/* <p>created so far.</p> */}
						</div>
					</div>

					<div className="col-sm-3 col-xs-6">
						<div
							className="tile-stats tile-green"
							style={{ height: 125 }}
							onClick={() => {
								this.props.router.navigate(`/home/Forms`);
							}}
						>
							<div
								className="icon"
								style={{ top: 0, bottom: 0, display: "flex" }}
							>
								<i
									className="fa fa-bars"
									style={{ alignSelf: "center", fontSize: 75 }}
								></i>
							</div>
							<div
								className="num"
								data-start="0"
								data-end="135"
								data-postfix=""
								data-duration="1500"
								data-delay="600"
							>
								{this.state.countStatistics?.FormCount ?? 0}
							</div>

							<h3>Forms</h3>
							{/* <p>this is the average value.</p> */}
						</div>
					</div>

					<div className="clear visible-xs"></div>

					<div className="col-sm-3 col-xs-6">
						<div
							className="tile-stats tile-aqua"
							style={{ height: 125 }}
							onClick={() => {
								this.props.router.navigate(`/home/Categories`);
							}}
						>
							<div
								className="icon"
								style={{ top: 0, bottom: 0, display: "flex" }}
							>
								<i
									className="fa fa-tags"
									style={{ alignSelf: "center", fontSize: 75 }}
								></i>
							</div>
							<div
								className="num"
								data-start="0"
								data-end="23"
								data-postfix=""
								data-duration="1500"
								data-delay="1200"
							>
								{this.state.countStatistics?.CategoriesCount ?? 0}
							</div>

							<h3>Categories</h3>
							<p></p>
						</div>
					</div>

					<div className="col-sm-3 col-xs-6">
						<div
							className="tile-stats tile-blue"
							style={{ height: 125 }}
							onClick={() => {
								this.props.router.navigate(`/home/Users`);
							}}
						>
							<div
								className="icon"
								style={{ top: 0, bottom: 0, display: "flex" }}
							>
								<i
									className="fa fa-users"
									style={{ alignSelf: "center", fontSize: 75 }}
								></i>
							</div>
							<div
								className="num"
								data-start="0"
								data-end="52"
								data-postfix=""
								data-duration="1500"
								data-delay="1800"
							>
								{this.state.graphStatistics?.amountOfUsersOnline ?? 0}
							</div>

							<h3>Users Online</h3>
							<p></p>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-sm-12">
						<div className="panel panel-primary" id="charts_env">
							<div className="panel-heading">
								<div className="panel-title">Accesses / Answers</div>

								<div className="panel-options">
									{/* <ul className="nav nav-tabs">
                                        <li className=""><a href="#area-chart" data-toggle="tab">Area Chart</a></li>
                                        <li className="active"><a href="#line-chart" data-toggle="tab">Line Charts</a></li>
                                        <li className=""><a href="#pie-chart" data-toggle="tab">Pie Chart</a></li>
                                    </ul> */}
								</div>
							</div>

							<div className="panel-body">
								<div className="tab-pane active" id="line-chart">
									<div
										id="line-chart-demo"
										className="morrischart"
										style={{ height: 500 }}
									></div>
								</div>
								{/* <div className="tab-content">
                                    <div className="tab-pane" id="area-chart">
                                        <div id="area-chart-demo"   className="morrischart" style={{height:300}}></div>
                                    </div>
                                    <div className="tab-pane active" id="line-chart">
                                        <div id="line-chart-demo"   className="morrischart" style={{height:300}}></div>
                                    </div>
                                    <div className="tab-pane" id="pie-chart">
                                        <div id="donut-chart-demo"  className="morrischart" style={{height:300}}></div>
                                    </div>
                                </div> */}
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default withRouter(Home);
