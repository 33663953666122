import axios from 'axios';

export const getToken = async (email, password) => {

    axios.defaults.withCredentials = true;
    //env file API_URL
    // console.log(process.env);
    return axios.post(`${process.env.REACT_APP_API_URL}/login`, {
        email,
        password
    })
    .then(response => {
        if(response.data.token !== "" && response.data.token !== null && response.data.token !== undefined){
            // console.log("antes...")
            //save token in local storage
            localStorage.setItem('token', response.data.token);
            // console.log("login token", response.data.token);
        }
        // console.log("depois...")

        return response.data;
    })
    .catch(error => {
        //get error response message
        console.log(error.response.data.error);
        return error?.response?.data?.error+"";
    });
};

export const refreshToken = async () => {
    axios.defaults.withCredentials = true;

    var cyphertoken = await localStorage.getItem('token');
    // console.log("cyphertoken", cyphertoken); 
    return await axios.get(`${process.env.REACT_APP_API_URL}/RefreshToken`, {
        params:{
            RefreshToken: cyphertoken
        }
    })
    .then(response => {
        if(response.data.token !== "" && response.data.token !== null && response.data.token !== undefined){
            //save token in local storage
            localStorage.setItem('token', response.data.token);
            // console.log("Refresh token", response.data.token);
        }

        return response.data;
    })
    .catch(error => {
        console.log("error", error);
        return "ERROR"+error;
    });
}