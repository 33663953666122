//import react
import React from "react";
import {
	getPermissionToVisualizeTemplateWithAllUsers,
	setPermissionToVisualizeTemplateWithAllUsers,
} from "../../api/templates";
import {
	GetUsersWithConnectionToFormAndPermissionStatus,
	SetUsersWithConnectionToFormAndPermissionStatus,
} from "../../api/users";

class UsersPermissionsToVisualize extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			template: props.SelectedTemplate,
			permissions: [],
		};
	}

	componentDidUpdate = () => {
		if (this.state.loading) this.LoadAll();

		window.jQuery("#modal-6").modal("show", { backdrop: "static" });

		// var script = document.getElementById("edit-script-modal-sites");

		// if(script){
		//     console.log("remove script!")
		//     script.remove();
		// }
		// var scriptx = document.createElement("script");
		// scriptx.id = "edit-script-modal-sites"
		// scriptx.innerHTML = "jQuery('#modal-6').modal('show', {backdrop: 'static'});";
		// document.body.appendChild(scriptx);
	};

	componentDidMount = () => {
		if (this.state.loading) this.LoadAll();
	};

	componentWillUnmount = () => {
		window.jQuery(".modal-backdrop").remove();
	};

	Submit = async () => {
		try {
			let result = await setPermissionToVisualizeTemplateWithAllUsers(
				this.state.permissions,
				this.state.template.id
			);

			if (result !== []) {
				this.props.CloseForm();
				return;
			}
		} catch (error) {
			console.log(error);
		}
	};

	PermissionChanged = (index) => {
		try {
			let permissions = this.state.permissions;
			permissions[index].permission = !permissions[index].permission;

			this.setState({
				permissions: permissions,
			});
		} catch (error) {
			console.log(error);
		}
	};

	LoadAll = async () => {
		// console.log("this.template", this.state.template);
		//users with access to the form

		const permissions = await getPermissionToVisualizeTemplateWithAllUsers(
			this.state.template.id
		);
		console.log("permissions", permissions);

		this.setState({
			loading: false,
			permissions: permissions,
		});
	};

	render() {
		if (this.state.loading) {
			return null;
		}

		return (
			<div className="modal fade" id="modal-6" data-backdrop="static">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<button
								type="button"
								className="close"
								data-dismiss="modal"
								aria-hidden="true"
								onClick={this.props.CloseFormNoChanges}
							></button>
							<h4 className="modal-title">
								{this.state.template.name} - Visualize Permission
							</h4>
						</div>

						<div className="modal-body">
							<table className="table table-bordered">
								<thead>
									<tr>
										<th scope="col" style={{ textAlign: "center" }}>
											User
										</th>
										<th scope="col" style={{ textAlign: "center" }}>
											Allow
										</th>
									</tr>
								</thead>
								<tbody>
									{this.state.permissions.map((user, index) => {
										return (
											<tr key={index}>
												<td style={{ textAlign: "center" }}>{user.username}</td>
												<td style={{ textAlign: "center" }}>
													{" "}
													<input
														type={"checkbox"}
														style={{ transform: "scale(2)" }}
														onChange={() => this.PermissionChanged(index)}
														checked={this.state.permissions[index].permission}
													></input>{" "}
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn" onClick={this.Submit}>
								Save
							</button>
							<button
								type="button"
								className="btn"
								data-dismiss="modal"
								onClick={this.props.CloseFormNoChanges}
							>
								Close
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default UsersPermissionsToVisualize;
