//import react
import React from "react";
import {
	setFormType,
	getFormType,
	getCategory,
	setCategory,
} from "../../utils/categoriesForm";
import { addCategory, updateCategory } from "../../api/categories";
import {
	ErrorNotification,
	SuccessNotification,
} from "../../utils/notifications";
import { GithubPicker } from "react-color";

class FormTemplate extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			Category: {
				CategoryName: "",
				Color: "#008B02",
			},
			SiteID: -1,
			FormType: "", // "Create" or "Edit"
			displayColorPicker: false,
			visible: true,
		};
	}

	componentDidUpdate = () => {
		if (this.state.loading) this.LoadAll();

		if (this.state.Category.Color === undefined) {
			return;
		}

		var script = document.getElementById("edit-script-modal-sites");

		if (script) {
			script.remove();
		}

		if (!this.state.visible) {
			return;
		}

		var scriptx = document.createElement("script");
		scriptx.id = "edit-script-modal-sites";
		scriptx.innerHTML =
			"jQuery('#modal-6').modal('show', {backdrop: 'static'});";
		document.body.appendChild(scriptx);
	};

	componentDidMount = () => {
		if (this.state.loading) this.LoadAll();
	};

	LoadAll = async () => {
		var FormType = getFormType();
		if (FormType === "Edit") {
			var category = getCategory();
			this.setState({
				Category: {
					CategoryName: category.name,
					Color: category.color,
					id: category.id,
				},
				visible: true,
			});
		}

		this.setState({
			loading: false,
			FormType: FormType,
		});
	};

	CloseForm = async () => {
		//reset state
		this.setState({
			loading: true,
			category: {},
			FormType: "", // "Create" or "Edit"
		});

		try {
			var scriptx = document.createElement("script");
			scriptx.id = "closemodal";
			scriptx.innerHTML = "jQuery('#modal-6').modal('hide');";
			document.body.appendChild(scriptx);
			document.findElementById("closemodal").remove();
		} catch (error) {}

		this.props.CloseForm();
	};

	Submit = async () => {
		this.handleClose();
		var FormType = getFormType();
		var result;

		if (FormType === "Create") {
			result = await this.Create();
		} else {
			result = await this.Update();
		}

		// console.log("Val returned ...", result, result===1);
		if (result === 1) {
			this.setState({ visible: false });
			this.CloseForm();
		}
	};

	Create = async () => {
		if (this.state.Category.CategoryName === "") {
			ErrorNotification("error", "Category name is required");
			return;
		}

		await addCategory(this.state.Category);
		return 1;
	};

	Update = async () => {
		if (this.state.Category.CategoryName === "") {
			ErrorNotification("error", "Category name is required");
			return;
		}

		await updateCategory(this.state.Category);
		return 1;
	};

	CategoryNameChanged = (value) => {
		var category = this.state.Category;
		category.CategoryName = value;
		this.setState({
			Category: category,
		});
	};

	_handleKeyDown = (key) => {
		if (key === "Enter") {
			this.Create();
		}
	};

	handleClick = () => {
		this.setState({ displayColorPicker: !this.state.displayColorPicker });
	};

	handleClose = () => {
		this.setState({ displayColorPicker: false });
	};

	handleChange = (Color) => {
		var category = this.state.Category;
		category.Color = Color.hex;

		this.setState({
			Category: category,
		});
	};

	render() {
		return (
			<div className="modal fade" id="modal-6" data-backdrop="static">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<button
								type="button"
								className="close"
								data-dismiss="modal"
								aria-hidden="true"
								onClick={this.CloseForm}
							></button>
							<h4 className="modal-title">{this.state.FormType} Category</h4>
						</div>

						<div className="modal-body custom-light-gray">
							<div className="mb-3">
								<label
									htmlFor="exampleFormControlInput1"
									className="form-label"
								>
									Name:
								</label>
								<input
									type="text"
									className="form-control"
									id="exampleFormControlInput1"
									placeholder="Name"
									value={this.state.Category.CategoryName}
									onFocus={() => this.handleClose()}
									onChange={(e) => this.CategoryNameChanged(e.target.value)}
									onKeyDown={(e) => this._handleKeyDown(e.key)}
								/>
							</div>
							<div className="mb-3 d-flex flex-row">
								<label
									htmlFor="exampleFormControlInput1"
									style={{ height: 20 }}
									className="form-label"
								>
									Color:
								</label>

								<div style={styles.swatch}>
									<div
										onClick={this.handleClick}
										style={{
											width: 50,
											height: 20,
											marginLeft: 20,
											borderWidth: 1,
											borderColor: "black",
											borderRadius: 1,
											backgroundColor: this.state.Category.Color,
											borderStyle: "double",
										}}
									/>
								</div>
								{this.state.displayColorPicker ? (
									<div style={styles.popover}>
										<div style={styles.cover} onClick={this.handleClose} />
										<GithubPicker
											color={this.state.Category.Color}
											onChange={this.handleChange}
										/>
									</div>
								) : null}
							</div>
						</div>

						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-esip-blue py-2 px-4"
								onClick={this.Submit}
							>
								{getFormType() === "Create" ? "Create" : "Update"}
							</button>
							<button
								type="button"
								className="btn btn-secondary py-2 px-4"
								data-dismiss="modal"
								onClick={this.CloseForm}
							>
								Close
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const styles = {
	default: {
		swatch: {
			padding: "5px",
			background: "#fff",
			borderRadius: "1px",
			boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
			display: "inline-block",
			cursor: "pointer",
		},
		popover: {
			position: "absolute",
			zindex: "2",
		},
	},
};

export default FormTemplate;
