//import react
import React from "react";
import {
	setFormType,
	getFormType,
	getCategory,
	setCategory,
} from "../../utils/categoriesForm";
import {
	addCategory,
	updateCategory,
	getCategoryAssociations,
	setCategoryAssociations,
} from "../../api/categories";
import {
	ErrorNotification,
	SuccessNotification,
} from "../../utils/notifications";
import { GithubPicker } from "react-color";
import Multiselect from "multiselect-react-dropdown";

class CategoryPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			category: this.props.Category,
			loading: true,
			sites: this.props.sites,
			templates: this.props.templates,
			selectedSites: [],
			selectedTemplates: [],
			visible: true,
		};
		// console.log(this.state.category)
	}

	componentDidUpdate = () => {
		if (this.state.loading) {
			this.LoadAll();
			return;
		}

		var script = document.getElementById("edit-script-modal-sites");

		if (script) {
			script.remove();
		}

		if (!this.state.visible) {
			return;
		}

		// console.log("SHOWSHOW!!!")

		var scriptx = document.createElement("script");
		scriptx.id = "edit-script-modal-sites";
		scriptx.innerHTML =
			"jQuery('#modal-6').modal('show', {backdrop: 'static'});";
		document.body.appendChild(scriptx);
	};

	componentWillUnmount() {
		// console.log("unmounting...")
		try {
			var scriptx = document.createElement("script");
			scriptx.id = "closemodal";
			scriptx.innerHTML = "$('.modal-backdrop').remove();"; //aqui o hide nao funciona porque a modal já não existe...
			document.body.appendChild(scriptx);
			document.findElementById("closemodal").remove();
		} catch (error) {}
	}

	componentDidMount = () => {
		if (this.state.loading) this.LoadAll();
	};

	LoadAll = async () => {
		try {
			var CategoriesAssociations = await getCategoryAssociations(
				this.state.category.id
			);

			// console.log("CategoriesAssociations", CategoriesAssociations)

			var selectedSites = [];
			var selectedTemplates = [];

			if (CategoriesAssociations != null) {
				//for each site in CategoriesAssiciations
				for (var i = 0; i < CategoriesAssociations.site.length; i++) {
					var SiteId = CategoriesAssociations.site[i].id;
					// console.log(SiteId);
					//find SiteID in sites
					var foundsite = this.state.sites.find((obj) => {
						return obj.id === SiteId;
					});

					if (
						foundsite === null ||
						SiteId === null ||
						SiteId === undefined ||
						foundsite === undefined
					)
						continue;

					selectedSites.push(foundsite);
				}
				//for each template in CategoriesAssiciations
				for (var i = 0; i < CategoriesAssociations.template.length; i++) {
					var TemplateId = CategoriesAssociations.template[i].id;
					// console.log(TemplateId);
					//find TemplateID in templates
					var foundtemplate = this.state.templates.find((obj) => {
						return obj.id === TemplateId;
					});

					if (
						foundtemplate === null ||
						TemplateId === null ||
						TemplateId === undefined ||
						foundtemplate === undefined
					)
						continue;

					// console.log(foundtemplate, "foundtemplate")
					selectedTemplates.push(foundtemplate);
				}
			}

			this.setState({
				loading: false,
				selectedSites: selectedSites,
				selectedTemplates: selectedTemplates,
				visible: true,
			});
		} catch (error) {
			console.log("error loading", error);
		}
	};

	CloseForm = async () => {
		//reset state
		this.setState({
			loading: true,
			category: {},
		});

		try {
			var scriptx = document.createElement("script");
			scriptx.id = "closemodal";
			scriptx.innerHTML = "jQuery('#modal-6').modal('hide');";
			document.body.appendChild(scriptx);
			document.findElementById("closemodal").remove();
		} catch (error) {}

		this.props.CloseForm();
	};

	onSelectSite = (value) => {
		this.setState({
			selectedSites: value,
		});
	};

	onRemoveSite = (value) => {
		this.setState({
			selectedSites: value,
		});
	};

	onSelectTemplate = (value) => {
		this.setState({
			selectedTemplates: value,
		});
	};

	onRemoveTemplate = (value) => {
		this.setState({
			selectedTemplates: value,
		});
	};

	Save = async () => {
		try {
			// console.log("categoryid",this.state.category.id);
			var AssociationRes = await setCategoryAssociations(
				this.state.category.id,
				this.state.selectedSites,
				this.state.selectedTemplates
			);
			if (AssociationRes !== null) {
				this.setState({
					visible: false,
				});
				SuccessNotification("Category saved successfully");
				this.CloseForm();
			}
		} catch (error) {
			ErrorNotification("Error saving Associations");
		}
	};

	render() {
		if (this.state.loading) return null;

		return (
			<div className="modal fade" id="modal-6" data-backdrop="static">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<button
								type="button"
								className="close"
								data-dismiss="modal"
								aria-hidden="true"
								onClick={this.props.CloseFormNoChanges}
							></button>
							<h4 className="modal-title">{this.state.category.name}</h4>
						</div>

						<div className="modal-body">
							<h6>Sites</h6>
							<Multiselect
								options={this.state.sites} // Options to display in the dropdown
								selectedValues={this.state.selectedSites} // Preselected value to persist in dropdown
								onSelect={this.onSelectSite} // Function will trigger on select event
								onRemove={this.onRemoveSite} // Function will trigger on remove event
								displayValue="name" // Property name to display in the dropdown options
								placeholder="Add a site"
							/>
							<h6 style={{ marginTop: 10 }}>Forms</h6>
							<Multiselect
								options={this.state.templates} // Options to display in the dropdown
								selectedValues={this.state.selectedTemplates} // Preselected value to persist in dropdown
								onSelect={this.onSelectTemplate} // Function will trigger on select event
								onRemove={this.onRemoveTemplate} // Function will trigger on remove event
								displayValue="name" // Property name to display in the dropdown options
								placeholder="Add a template"
							/>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-secondary py-2 px-4"
								data-dismiss="modal"
								onClick={this.props.CloseFormNoChanges}
							>
								Close
							</button>
							<button
								type="button"
								className="btn btn-esip-blue py-2 px-4"
								onClick={() => this.Save()}
							>
								Save
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const styles = {
	default: {
		swatch: {
			padding: "5px",
			background: "#fff",
			borderRadius: "1px",
			boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
			display: "inline-block",
			cursor: "pointer",
		},
		popover: {
			position: "absolute",
			zindex: "2",
		},
	},
};

export default CategoryPage;
