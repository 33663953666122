//import react
import React from "react";
import {
	setFormType,
	getFormType,
	getUser,
	setUser,
} from "../../utils/usersForm.js";
import {
	addUser,
	updateUser,
	resetPassword,
	restoreUser,
} from "../../api/users";
import {
	ErrorNotification,
	SuccessNotification,
} from "../../utils/notifications";
import Multiselect from "multiselect-react-dropdown";
import Swal from "sweetalert2";

class FormTemplate extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			FormType: "", // "Create" or "Edit"
			user: null,
			sites: this.props.sites,
			categories: this.props.categories,
			finishing: false,
			ShowResetForm: false,
			PasswordConfirmationChanged: "",
			PasswordChanged: "",
			visible: true,
		};
	}

	componentDidUpdate = () => {
		if (this.state.loading) this.LoadAll();

		var script = document.getElementById("edit-script-modal-sites");

		if (script) {
			script.remove();
		}

		if (!this.state.visible) {
			return;
		}
		// console.log("show!....")

		var scriptx = document.createElement("script");
		scriptx.id = "edit-script-modal-sites";
		scriptx.innerHTML =
			"jQuery('#modal-6').modal('show', {backdrop: 'static'});";
		document.body.appendChild(scriptx);
	};

	componentWillUnmount() {
		console.log("unmounting...");
		try {
			var scriptx = document.createElement("script");
			scriptx.id = "closemodal";
			scriptx.innerHTML = "$('.modal-backdrop').remove();"; //aqui o hide nao funciona porque a modal já não existe...
			document.body.appendChild(scriptx);
			document.findElementById("closemodal").remove();
		} catch (error) {}
	}

	componentDidMount = () => {
		if (this.state.loading) this.LoadAll();
	};

	LoadAll = async () => {
		var FormType = getFormType();

		var NewUser = {
			Username: "",
			UserID: -1,
			IsActive: true,
			IsAdmin: false,
			IsSuperUser: false,
			defaultSite: [],
			email: "",
			password: "",
			validationPassword: "",
			categories: [],
			visible: true,
			name: "",
			notes: "",
		};

		if (FormType === "Edit") {
			var user = getUser();

			// console.log("getUser", user);

			NewUser = {
				Username: user.username,
				UserID: user.id,
				IsActive: user.isActive,
				IsAdmin: user.isAdmin,
				IsSuperUser: user.isSuperUser,
				IsBKOUser: user.isBKOUser,
				defaultSite: user.defaultSite ?? [],
				email: user.email,
				categories: user.categories ?? [],
				name: user.name ?? "",
				notes: user.notes ?? "",
			};
		}

		this.setState({
			loading: false,
			FormType: FormType,
			user: NewUser,
		});
	};

	CloseForm = async () => {
		//reset state
		this.setState({
			loading: true,
			site: {},
			FormType: "", // "Create" or "Edit"
		});

		try {
			// console.log("hide modal...");
			var scriptx = document.createElement("script");
			scriptx.id = "closemodal";
			scriptx.innerHTML = "jQuery('#modal-6').modal('hide');";
			document.body.appendChild(scriptx);

			// document.findElementById("closemodal").remove();
		} catch (error) {}

		this.props.CloseForm();
	};

	Submit = async () => {
		var FormType = getFormType();

		this.setState(
			{
				finishing: true,
			},
			() => {
				(async () => {
					if (FormType === "Create") {
						await this.Create();
					} else {
						await this.Update();
					}

					this.setState({
						finishing: false,
					});
				})();
			}
		);
	};

	Create = async () => {
		if (this.state.user.Username === "") {
			ErrorNotification("error", "Username is required");
			return;
		}
		if (
			this.state.user.email === "" ||
			!this.state.user.email + "".includes("@")
		) {
			ErrorNotification("error", "Email is required");
			return;
		}
		if (this.state.user.password === "") {
			ErrorNotification("error", "password is required");
			return;
		}
		if (this.state.user.password !== this.state.user.validationPassword) {
			ErrorNotification("error", "Passwords do not match");
			return;
		}
		// if(this.state.user.defaultSite.length === 0 && !this.state.user.IsAdmin){
		//     ErrorNotification("error", "Site is required");
		//     return;
		// }

		let res = await addUser(this.state.user);

		// console.log("res->", res)

		if (res === 101) {
			//user already exists but is deleted. restore it?
			await Swal.fire({
				title: "Warning",
				text: "The username or email have already been on deleted user. Do you want to recover it?",
				icon: "warning",
				showConfirmButton: true,
				showCloseButton: true,
				showCancelButton: true,
			}).then(async (result) => {
				if (result.isConfirmed) {
					await restoreUser(this.state.user);
				}
			});
		}
		this.setState({ visible: false });
		this.CloseForm();
	};

	ResetPassword = async () => {
		this.setState({
			ShowResetForm: true,
		});
	};

	updateUserPassword = async () => {
		// console.log(this.state.user.email, this.state.PasswordConfirmationChanged, this.state.NewPassword);
		let result = await resetPassword(
			this.state.user.email,
			this.state.PasswordConfirmationChanged,
			this.state.NewPassword
		);
		if (result === 1) {
			this.setState({
				ShowResetForm: false,
				PasswordConfirmationChanged: "",
				NewPassword: "",
			});
		}
	};

	Update = async () => {
		if (this.state.user.Username === "") {
			ErrorNotification("error", "Username is required");
			return;
		}
		if (
			this.state.user.email === "" ||
			!this.state.user.email + "".includes("@")
		) {
			ErrorNotification("error", "Email is required");
			return;
		}
		// if(this.state.user.defaultSite.length === 0 && !this.state.user.IsAdmin){
		//     console.log("site is required", this.state.user.defaultSite);
		//     ErrorNotification("error", "Site is required");
		//     return;
		// }

		// console.log(this.state.user);

		await updateUser(this.state.user);
		this.setState({ visible: false });

		this.CloseForm();
	};

	UsernameChanged = (value) => {
		var U = this.state.user;

		U.Username = value;

		this.setState({
			user: U,
		});
	};

	NameChanged = (value) => {
		var U = this.state.user;

		U.name = value;

		this.setState({
			user: U,
		});
	};

	EmailChanged = (value) => {
		var U = this.state.user;

		U.email = value;

		this.setState({
			user: U,
		});
	};

	PasswordChanged = (value) => {
		var U = this.state.user;

		U.password = value;

		this.setState({
			user: U,
		});
	};

	ValidationPasswordChanged = (value) => {
		var U = this.state.user;

		U.validationPassword = value;

		this.setState({
			user: U,
		});
	};

	NotesChanged = (value) => {
		var U = this.state.user;

		if (value.length > 80) {
			return;
		}

		U.notes = value;

		this.setState({
			user: U,
		});
	};

	AdministradorChanged = () => {
		var U = this.state.user;

		U.IsAdmin = !U.IsAdmin;

		this.setState({
			user: U,
		});
	};

	SuperUserChanged = () => {
		var U = this.state.user;

		U.IsSuperUser = !U.IsSuperUser;

		this.setState({
			user: U,
		});
	};

	ActiveChanged = () => {
		var U = this.state.user;

		U.IsActive = !U.IsActive;

		this.setState({
			user: U,
		});
	};

	BKOUserChanged = () => {
		var U = this.state.user;

		U.IsBKOUser = !U.IsBKOUser;

		this.setState({
			user: U,
		});
	};

	onSiteChanged = (value) => {
		var U = this.state.user;

		U.defaultSite = value;

		this.setState({
			user: U,
		});
	};

	onCategoryChanged = (value) => {
		var U = this.state.user;

		U.categories = value;

		this.setState({
			user: U,
		});
	};

	DefaultSiteChanged = (value) => {
		// var U = this.state.user;
		// //find site
		// // console.log(this.state.sites)
		// var site = this.state.sites.find(s => {return s.id+"" === value+""});
		// U.defaultSite = site.id;
		// this.setState({
		//     user: U
		// });
	};

	render() {
		if (this.state.loading) {
			return null;
		}

		if (this.state.ShowResetForm) {
			return (
				<div className="modal fade" id="modal-6" data-backdrop="static">
					<div className="modal-dialog">
						<div className="modal-content">
							<div className="modal-header">
								<button
									type="button"
									className="close"
									data-dismiss="modal"
									aria-label="Close"
									onClick={this.CloseForm}
								></button>
								<h4 className="modal-title">{this.state.FormType} User</h4>
							</div>
							<div className="modal-body custom-light-gray">
								<div style={{ marginBottom: 10 }}>
									<label htmlFor="NewPassword" className="form-label">
										New Password:
									</label>
									<input
										key={"NewPasswordReset"}
										type="password"
										className="form-control"
										id="NewPassword"
										placeholder="NewPassword"
										value={this.state.NewPassword}
										onChange={(e) =>
											this.setState({ NewPassword: e.target.value })
										}
									/>
								</div>
								<div style={{ marginBottom: 10 }}>
									<label
										htmlFor="PasswordConfirmationChanged"
										className="form-label"
									>
										Password:
									</label>
									<input
										key={"NewPasswordConfirmationReset"}
										type="password"
										className="form-control"
										id="PasswordConfirmationChanged"
										placeholder="PasswordConfirmationChanged"
										value={this.state.PasswordConfirmationChanged}
										onChange={(e) =>
											this.setState({
												PasswordConfirmationChanged: e.target.value,
											})
										}
									/>
								</div>
							</div>
							<div className="modal-footer">
								<button
									type="button"
									className="btn btn-esip-red py-2"
									onClick={this.updateUserPassword}
								>
									Reset Password
								</button>
								<button
									type="button"
									className="btn btn-secondary py-2 px-4"
									style={{ margin: "0 0 0 auto", marginLeft: 5 }}
									disabled={this.state.finishing}
									data-bs-dismiss="modal"
									onClick={() => {
										this.setState({ ShowResetForm: false });
									}}
								>
									Close
								</button>
							</div>
						</div>
					</div>
				</div>
			);
		}

		return (
			<div className="modal fade" id="modal-6" data-backdrop="static">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<button
								type="button"
								className="close"
								data-dismiss="modal"
								aria-label="Close"
								onClick={this.CloseForm}
							></button>
							<h4 className="modal-title">{this.state.FormType} User</h4>
						</div>
						<div className="modal-body custom-light-gray">
							<div style={{ marginBottom: 10 }}>
								<label htmlFor="Name" className="form-label">
									Name:
								</label>
								<input
									type="text"
									className="form-control"
									id="Name"
									placeholder="Name"
									value={this.state.user.name}
									onChange={(e) => this.NameChanged(e.target.value)}
								/>
							</div>
							<div style={{ marginBottom: 10 }}>
								<label htmlFor="Username" className="form-label">
									Username:
								</label>
								<input
									type="text"
									className="form-control"
									id="Username"
									placeholder="Username"
									value={this.state.user.Username}
									onChange={(e) => this.UsernameChanged(e.target.value)}
								/>
							</div>
							<div style={{ marginBottom: 10 }}>
								<label htmlFor="Email" className="form-label">
									Email:
								</label>
								<input
									type="email"
									className="form-control"
									id="Email"
									placeholder="Email"
									value={this.state.user.email}
									onChange={(e) => this.EmailChanged(e.target.value)}
								/>
							</div>
							<div
								style={{
									marginBottom: 10,
									display: this.state.FormType !== "Create" ? "none" : "",
								}}
							>
								<label htmlFor="Password" className="form-label">
									Password:
								</label>
								<input
									type="password"
									className="form-control"
									id="Password"
									placeholder="Password"
									value={this.state.user.password}
									onChange={(e) => this.PasswordChanged(e.target.value)}
								/>
							</div>
							<div
								style={{
									marginBottom: 10,
									display: this.state.FormType !== "Create" ? "none" : "",
								}}
							>
								<label htmlFor="ValidationPassword" className="form-label">
									Password Confirmation:
								</label>
								<input
									type="password"
									className="form-control"
									id="ValidationPassword"
									placeholder="Password Confirmation"
									value={this.state.user.validationPassword}
									onChange={(e) =>
										this.ValidationPasswordChanged(e.target.value)
									}
								/>
							</div>
							<div style={{ marginBottom: 10 }}>
								<label htmlFor="Note" className="form-label">
									Notes:
								</label>
								<textarea
									type="text"
									className="form-control"
									style={{ resize: "none", height: 100 }}
									id="Note"
									placeholder="Note"
									value={this.state.user.notes}
									onChange={(e) => this.NotesChanged(e.target.value)}
								/>
							</div>
							<div style={{ marginBottom: 10 }}>
								<label htmlFor="DefaultSite" className="form-label">
									Sites:
								</label>
								<Multiselect
									options={this.state.sites} // Options to display in the dropdown
									selectedValues={this.state.user.defaultSite} // Preselected value to persist in dropdown
									onSelect={this.onSiteChanged} // Function will trigger on select event
									onRemove={this.onSiteChanged} // Function will trigger on remove event
									disable={this.state.user.IsAdmin}
									displayValue="name" // Property name to display in the dropdown options
									placeholder="Add a site"
								/>

								{/* <select className='form-select' value={this.state.user.defaultSite} disabled={this.state.user.IsAdmin} onChange={(value) => this.DefaultSiteChanged(value.target.value)}>
                                    {
                                        this.state.sites.map((site, index) => {
                                            return <option key={site.id} value={site.id}>{site.name}</option>
                                        })
                                    }
                                </select> */}
							</div>
							<div style={{ marginBottom: 10 }}>
								<label htmlFor="DefaultCategories" className="form-label">
									Categories:
								</label>
								<Multiselect
									options={this.state.categories} // Options to display in the dropdown
									selectedValues={this.state.user.categories} // Preselected value to persist in dropdown
									onSelect={this.onCategoryChanged} // Function will trigger on select event
									onRemove={this.onCategoryChanged} // Function will trigger on remove event
									disable={this.state.user.IsAdmin}
									displayValue="name" // Property name to display in the dropdown options
									placeholder="Add a Category"
								/>

								{/* <select className='form-select' value={this.state.user.defaultSite} disabled={this.state.user.IsAdmin} onChange={(value) => this.DefaultSiteChanged(value.target.value)}>
                                    {
                                        this.state.sites.map((site, index) => {
                                            return <option key={site.id} value={site.id}>{site.name}</option>
                                        })
                                    }
                                </select> */}
							</div>
							<div
								className="d-flex flex-row justify-content-center w-100"
								style={{ display: "flex", justifyContent: "center" }}
							>
								<div style={{ width: "45%" }}>
									<label
										htmlFor="IsAdmin"
										className="form-label"
										style={{ width: "70%" }}
									>
										Administrator:
									</label>
									<input
										type="checkbox"
										className="form-check-input"
										style={{ marginLeft: "5px" }}
										id="IsAdmin"
										onChange={() => this.AdministradorChanged()}
										checked={this.state.user.IsAdmin}
									/>
								</div>
								<div style={{ width: "45%" }}>
									<label
										htmlFor="IsSuperUser"
										className="form-label"
										style={{ width: "70%" }}
									>
										SuperUser:
									</label>
									<input
										type="checkbox"
										className="form-check-input"
										id="IsSuperUser"
										style={{ marginLeft: "5px" }}
										onChange={() => this.SuperUserChanged()}
										checked={this.state.user.IsSuperUser}
									/>
								</div>
							</div>
							<div
								className="d-flex flex-row justify-content-center w-100"
								style={{ display: "flex", justifyContent: "center" }}
							>
								<div style={{ width: "45%" }}>
									<label
										htmlFor="IsActive"
										className="form-label"
										style={{ width: "70%" }}
									>
										Active:
									</label>
									<input
										type="checkbox"
										className="form-check-input"
										id="IsActive"
										style={{ marginLeft: "5px" }}
										onChange={() => this.ActiveChanged()}
										checked={this.state.user.IsActive}
									/>
								</div>
								<div style={{ width: "45%" }}>
									<label
										htmlFor="IsActive"
										className="form-label"
										style={{ width: "70%" }}
									>
										Backoffice User:
									</label>
									<input
										type="checkbox"
										className="form-check-input"
										id="IsActive"
										style={{ marginLeft: "5px" }}
										onChange={() => this.BKOUserChanged()}
										checked={this.state.user.IsBKOUser}
									/>
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn"
								onClick={this.ResetPassword}
							>
								Reset Password
							</button>
							<button
								type="button"
								className="btn"
								style={{ margin: "5 0 0 5" }}
								disabled={this.state.finishing}
								onClick={this.Submit}
							>
								{this.state.FormType === "Edit" ? "Save" : this.state.FormType}
							</button>
							<button
								type="button"
								className="btn"
								disabled={this.state.finishing}
								data-dismiss="modal"
								onClick={this.CloseForm}
							>
								Close
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default FormTemplate;
