import axios from 'axios';
import { ErrorNotification, SuccessNotification } from "../utils/notifications"
export const getCategories = async () => {
    axios.defaults.withCredentials = true

    const localtoken = await localStorage.getItem('token')

    try {
        return axios.get(`${process.env.REACT_APP_API_URL}/ListCategories`, {
            params: {
                token: localtoken
            }
        })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            return [];
        });
    } catch (error) {
        return [];
    }
};


export const getCategoryAssociations = async (id) => {

    const localtoken = await localStorage.getItem('token')
    try {
        return axios.get(`${process.env.REACT_APP_API_URL}/CategoryAssociations`, {
            params: {
                CategoryID: id,
                token: localtoken
            }
        })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            return null;
        });
    } catch (error) {
        return null;
    }
}

export const setCategoryAssociations = async (id, siteAssociations, templateAssociations) => {
    try {

        const localtoken = await localStorage.getItem('token');
        return axios.post(`${process.env.REACT_APP_API_URL}/CategoryAssociations`, {
            CategoryID: id,
            SiteAssociations: siteAssociations,
            TemplateAssociations: templateAssociations,
            token: localtoken
        })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            return null;
        });
    } catch (error) {
        return null;
    }
}

export const addCategory = async (category) => {
    axios.defaults.withCredentials = true
    const localtoken = await localStorage.getItem('token');

    return axios.post(`${process.env.REACT_APP_API_URL}/CreateCategory`, {
        Category: category,
        token: localtoken
    })
    .then(response => {
        SuccessNotification("success", "Category "+category.CategoryName+" has been successfully created.");
        return response.data;
    })
    .catch(error => {
        ErrorNotification("error","Category Creation Failed");
        return "ERROR"+error;
    });
}


export const updateCategory = async (category) => {
    axios.defaults.withCredentials = true
    const localtoken = await localStorage.getItem('token');

    return axios.post(`${process.env.REACT_APP_API_URL}/UpdateCategory`, {
        Category: category,
        token: localtoken
    })
    .then(response => {
        SuccessNotification("success", "Category "+category.CategoryName+" has been successfully created.");
        return response.data;
    })
    .catch(error => {
        ErrorNotification("error","Category Creation Failed");
        return "ERROR"+error;
    });
}


export const deleteCategory = async (id) => {
    axios.defaults.withCredentials = true;

    const localtoken = await localStorage.getItem('token');

    return axios.post(`${process.env.REACT_APP_API_URL}/DeleteCategory`, {
        Category: id,
        token: localtoken
    })
    .then(response => {
        
        SuccessNotification("success", "Category deleted successfully.");
        return response.data;
    })
    .catch(error => {
        ErrorNotification("error", "Error deleting category - " + error.response.data);
        return "ERROR"+error;
    });
}

export const getSiteCategories = async (id) => { 
    axios.defaults.withCredentials = true;
    const localtoken = await localStorage.getItem('token');
    return axios.get(`${process.env.REACT_APP_API_URL}/SiteCategories`, {
        params: {
            SiteID: id,
            token: localtoken
        }
    })
    .then(response => { 
        return response.data;
    })
    .catch(error => {
        ErrorNotification("error", "Error getting site categories... " + id);
        return "ERROR"+error;
    });
}