import axios from 'axios';
import { ErrorNotification, SuccessNotification } from "../utils/notifications"

export const getStatistics = async () => {
    axios.defaults.withCredentials = true
    const localtoken = localStorage.getItem('token');

    return axios.get(`${process.env.REACT_APP_API_URL}/getStatistics`, {
        params:{
            token: localtoken
        }
    })
    .then(response => {
        return response.data;
    })
    .catch(error => {
        return "ERROR"+error;
    });
}

export const GraphStatistics = async () => {
    axios.defaults.withCredentials = true
    const localtoken = localStorage.getItem('token');

    return axios.get(`${process.env.REACT_APP_API_URL}/Statistics`, {
        params:{
            token: localtoken
        }
    })
    .then(response => {
        return response.data;
    })
    .catch(error => {
        return "ERROR"+error;
    });
}
