//import react
import React from "react";
import { DeleteTemplate } from "../../api/templates";

class DeleteForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			template: this.props.template,
		};
	}

	componentDidMount = () => {
		if (this.state.loading) this.LoadAll();
	};

	componentDidUpdate = () => {
		var script = document.getElementById("edit-script-modal-sites");

		if (script) {
			console.log("remove script!");
			script.remove();
		}
		var scriptx = document.createElement("script");
		scriptx.id = "edit-script-modal-sites";
		scriptx.innerHTML =
			"jQuery('#modal-6').modal('show', {backdrop: 'static'});";
		document.body.appendChild(scriptx);
	};

	LoadAll = async () => {
		this.setState({ loading: false });
	};

	CloseForm = async () => {
		//reset state
		this.setState({
			loading: true,
			template: {},
		});

		this.props.CloseForm();
	};

	componentWillUnmount = () => {
		window.jQuery(".modal-backdrop").remove();
	};

	Delete = async () => {
		// console.log("Delete", this.state.template.id);
		await DeleteTemplate(this.state.template.id);

		this.CloseForm();
	};

	render() {
		return this.state.template === undefined ||
			this.state.template === null ? null : (
			<div className="modal fade" id="modal-6" data-backdrop="static">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<button
								type="button"
								className="close"
								data-dismiss="modal"
								aria-hidden="true"
								onClick={() => this.CloseForm()}
							></button>
							<h4 className="modal-title">
								Delete Template {this.state.template.name} ?
							</h4>
						</div>

						<div className="modal-body">
							<div className="row">
								<div className="col-md-12">
									<div className="form-group no-margin">
										<p>
											Are you sure you want to delete{" "}
											<b>{this.state.template.name}</b>?
										</p>
										{/* <label className="control-label">Name:</label> */}
										{/* <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Name" ref={(input) => this.SiteNameInputText = input} value={this.state.SiteName} onChange={(e) => this.SiteNameChanged(e.target.value)} onKeyDown={(e) => this._handleKeyDown(e.key)} /> */}
									</div>
								</div>
							</div>
						</div>

						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-danger py-2 px-4"
								data-dismiss="modal"
								onClick={() => this.Delete()}
							>
								Delete
							</button>
							<button
								type="button"
								className="btn btn-secondary py-2 px-4"
								data-dismiss="modal"
								onClick={() => this.CloseForm()}
							>
								Close
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default DeleteForm;
