//import react
import React from "react";
import logo from "../logo.svg";
import "../App.css";
import { getAccessToken, setAccessToken } from "../utils/accessToken";
import { getToken } from "../api/getToken";
import Sites from "./Sites";
import Forms from "./Templates";
import Users from "./Users";
import Settings from "./Settings";
import Categories from "./Categories";
import Home from "./Home";
import { getUser } from "../utils/loggedUser";

import {
	Navigate,
	Outlet,
	useLocation,
	useNavigate,
	useParams,
	BrowserRouter,
	Route,
	Routes,
	useHistory,
	Link,
} from "react-router-dom";

function withRouter(Component) {
	function ComponentWithRouterProp(props) {
		let location = useLocation();
		let navigate = useNavigate();
		let params = useParams();
		return <Component {...props} router={{ location, navigate, params }} />;
	}
	return ComponentWithRouterProp;
}

class HomePage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			selectedMenu: "Home",
			user: null,
		};
		// console.log("homepage constructor");
	}

	componentDidUpdate = () => {
		if (this.state.loading) this.loadToken();

		// if(this.state.selectedMenu !== window.location.hash.substring(1)){
		//     this.ChangeSelectedMenu(window.location.hash.substring(1));
		// }
	};

	componentDidMount = () => {
		if (this.state.loading) this.loadToken();
		// this.props.router.setRouteLeaveHook(this.props.route, this.onLeave);

		if (window.location.pathname.split("/")[2] !== undefined) {
			this.ChangeSelectedMenu(window.location.pathname.split("/")[2]);
		}

		var script = null;

		script = document.createElement("script");
		script.id = "neon-custom-sidebar";
		script.src = "/assets/js/joinable.js";
		script.async = true;
		document.body.appendChild(script);

		script = document.createElement("script");
		script.id = "neon-custom-sidebar";
		script.src = "/assets/js/resizeable.js";
		script.async = true;
		document.body.appendChild(script);

		script = document.createElement("script");
		script.id = "neon-custom-sidebar";
		script.src = "/assets/js/neon-api.js";
		script.async = true;
		document.body.appendChild(script);

		script = document.createElement("script");
		script.id = "neon-custom-sidebar";
		script.src = "/assets/js/neon-custom.js";
		script.async = true;
		document.body.appendChild(script);
	};

	ChangeSelectedMenu = (menu) => {
		// console.log("change selected menu", menu);

		if (menu === this.state.selectedMenu) {
			this.setState({
				refresh: this.state.refresh === undefined ? 1 : this.state.refresh + 1,
			});
			return;
		}

		this.setState({
			selectedMenu: menu,
			refresh: 1,
		});
	};

	loadToken = async () => {
		let token = getAccessToken();
		let user = getUser();

		// console.log("vars",token,user);

		if (
			token === "" ||
			token === null ||
			token === undefined ||
			user === undefined ||
			user === null
		) {
			// console.log("token1", "this.props.router.navigate");
			// this.props.router.navigate("/login");
			this.setState({
				token: null,
				loading: false,
			});
			return;
		}

		this.setState({
			loading: false,
			user: user,
			token: token,
		});
	};

	render() {
		if (this.state.loading) return null;

		if (
			this.state.token === null ||
			this.state.token === undefined ||
			this.state.token === ""
		) {
			return (
				<Navigate to="/login" />
				// <p>{getAccessToken()}</p>
			);
		}

		return (
			<div className="page-container">
				<div className="sidebar-menu">
					<div className="sidebar-menu-inner" style={{ height: "100%" }}>
						<header className="logo-env">
							<div className="logo">
								{/* <a href="https://demo.neontheme.com/dashboard/main/"> */}
								<img
									src={require("../assets/logoH@2xv2.png")}
									width={150}
									alt="Logo UpWise"
								/>
								{/* <p style={{fontWeight:'bold', fontSize: 35, color:'white', width:120, textAlign:'center'}}>UpWISE!</p> */}
								{/* </a> */}
							</div>
							<div className="sidebar-collapse">
								<a className="sidebar-collapse-icon with-animation">
									<i className="entypo-menu"></i>
								</a>
							</div>
							<div className="sidebar-mobile-menu visible-xs">
								<a href="#" className="with-animation">
									<i className="entypo-menu"></i>
								</a>
							</div>
						</header>
						<ul id="main-menu" className="main-menu">
							<li className="root-level">
								<Link
									to="/home"
									// href="#"
									// onClick={() => {
									// 	this.props.router.navigate(`/home`);
									// }}
								>
									<i className="fa fa-home" style={{ width: 30 }}></i>
									<span className="title">Home</span>
								</Link>
							</li>
							{this.state.user.isAdmin ||
							this.state.user.isSuperUser ||
							this.state.user.isBKOUser ? (
								<li className="root-level">
									<Link
										to="/home/Sites"
										// href="#"
										// onClick={() => {
										// 	this.props.router.navigate(`/home/Sites`);
										// }}
									>
										<i className="fa fa-building" style={{ width: 30 }}></i>
										<span className="title">Sites</span>
									</Link>
								</li>
							) : null}
							{this.state.user.isAdmin || this.state.user.isSuperUser ? (
								<li className="root-level">
									<Link
										to="/home/Forms"
										// href="#"
										// onClick={() => {
										// 	this.props.router.navigate(`/home/Forms`);
										// }}
									>
										<i className="fa fa-list" style={{ width: 30 }}></i>
										<span className="title">Forms</span>
									</Link>
								</li>
							) : null}
							{this.state.user.isAdmin || this.state.user.isSuperUser ? (
								<>
									<li className="root-level">
										<Link
											to="/home/Categories"
											// href="#"
											// onClick={() => {
											// 	this.props.router.navigate(`/home/Categories`);
											// }}
										>
											<i className="fa fa-tags" style={{ width: 30 }}></i>
											<span className="title">Categories</span>
										</Link>
									</li>
									<li className="root-level">
										<Link
											to="/home/Users"
											// href="#"
											// onClick={() => {
											// 	this.props.router.navigate(`/home/Users`);
											// }}
										>
											<i className="fa fa-users" style={{ width: 30 }}></i>
											<span className="title">Users</span>
										</Link>
									</li>
								</>
							) : null}
							<li className="root-level">
								<Link
									to="/home/Settings"
									// onClick={() => {
									// 	this.props.router.navigate(`/home/Settings`);
									// }}
								>
									<i className="fa fa-gear" style={{ width: 30 }}></i>
									<span className="title">Settings</span>
								</Link>
							</li>
							<li className="root-level">
								<a
									href="#"
									onClick={() => {
										setAccessToken("");
										localStorage.removeItem("token");
										// this.props.router.navigate("/login");
										window.location.reload(false);
									}}
								>
									<i className="fa fa-sign-out" style={{ width: 30 }}></i>
									<span className="title">Logout</span>
								</a>
							</li>
							<li className="root-level">
								<p
									style={{
										color: "rgb(170, 171, 174)",
										textAlign: "center",
										marginTop: 10,
									}}
								>
									Version 1.5.6
								</p>
							</li>
						</ul>
					</div>
				</div>
				<div className="main-content">
					{
						// window.location.pathname.split("/")[2] !== undefined ?
						<Outlet context={{ refreshstate: this.state.refresh }} />
						// :
						//     <div>
						//         <Home />
						//     </div>
					}
				</div>
			</div>
		);

		return (
			<div style={{ width: "100%", height: "100%" }}>
				{/* <div id="home" className={`main-content`}> */}
				<div className="main-content">
					<Outlet context={{ refreshstate: this.state.refresh }} />
				</div>

				{/* <div id="home" className={`col-md-10 p-5 overflow-auto ${this.state.selectedMenu === "Home" ? "content" : "d-none"}`}>
					<h1>Home</h1>
                </div>

                <div id="sites" className={`col-md-10 p-5 overflow-auto ${this.state.selectedMenu === "Sites" ? "content" : "d-none"}`}>
                    <Sites forceRefresh={this.state.refresh}/>
                </div>

                
                <div id="forms" className={`col-md-10 p-5 overflow-auto ${this.state.selectedMenu === "Forms" ? "content" : "d-none"}`}>
					<Forms/>
                </div>
                
				<div id="categories" className={`col-md-10 p-5 overflow-auto ${this.state.selectedMenu === "Categories" ? "content" : "d-none"}`}>
					<Categories/>
                </div>
				<div id="users" className={`col-md-10 p-5 overflow-auto ${this.state.selectedMenu === "Users" ? "content" : "d-none"}`} >
					<Users/>
                </div>
				<div id="settings" className={`col-md-10 p-5 overflow-auto ${this.state.selectedMenu === "Settings" ? "content" : "d-none"}`} >
					<Settings/>
                </div> */}
			</div>
			// </div>
		);
	}
}

export default withRouter(HomePage);
