import { Store } from "react-notifications-component";

export const SuccessNotification =  async (title, Body) => {
  Store.addNotification({
    title: title,
    message: Body,
    type: "success",
    insert: "bottom",
    container: "bottom-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 3000,
      onScreen: true,
    },
  });
  return null;
}

export const InfoNotification =  async (title, Body) => {
  Store.addNotification({
    title: title,
    message: Body,
    type: "info",
    insert: "bottom",
    container: "bottom-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 10000,
      onScreen: true,
    },
  });
  return null;
}

export const ErrorNotification =  async (title, Body) => {
  Store.addNotification({
    title: title,
    message: Body,
    type: "danger",
    insert: "bottom",
    container: "bottom-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 3000,
      onScreen: true,
    },
  });
  return null;
}


export const StickyErrorNotification =  async (title, Body) => {
  Store.addNotification({
    title: title,
    message: Body,
    type: "danger",
    insert: "center",
    container: "center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"]
     
  });
  return null;
}
