//import react
import React from 'react';


class Privacy extends React.Component {

  render(){
    return (
        <div style={{overflow:'auto', width: '100%', height:'100%', padding: 20}}>
            <h2 style={{color:'#224099', textAlign:'center', marginBottom:100, fontSize:40}}>PRIVACY POLICY</h2>
            <p style={{textAlign:'justify'}}>This Privacy Notice announces the privacy policy of Thai Union Group PCL. and its affiliates in Thailand (&ldquo;TU Group&rdquo;) related to the collection, use and disclosure of personal information which enables the identification of such person, whether directly or indirectly (&ldquo;Personal Data&rdquo;) through various channels, both online and offline. This includes, for example, information of contractors, information collected through websites, information collected through points of sale and events, information collected from social networks such as Facebook and search engines such as Google. Your Personal Data will be collected, used and disclosed in accordance with provisions of the privacy law and other applicable laws and regulations. This Notice provides important information in the following areas:</p>
            <p style={{textAlign:'justify'}}><strong>1. Objectives for Personal Data Collection</strong></p>
            <p style={{textAlign:'justify'}}>TU Group will collect, use and disclose your Personal Data for management and administrative purposes in order to ensure that TU Group is able to run the business with you appropriately, effectively and lawfully in order to fulfil any and all obligations under contracts, to perform transactions, to provide suitable products and services, to respond to orders and requests, to improve the quality of products and services, or to pursue any other legitimate interests of TU Group.</p>
            <p style={{textAlign:'justify'}}>If you have provided Personal Data of any third party to us, please ensure that such third party has acknowledged this Privacy Policy or granted consent for processing their personal data where applicable.</p>
            <p style={{textAlign:'justify'}}>You are free to decide whether you will allow TU Group to collect your Personal Data. However, please kindly note that if you decide not to provide your Personal Data, you may not be able to earn some certain benefits i.e. TU Group may have insufficient information to enter into a contract with you or perform our obligations or commitments in some circumstances.</p>
            <p style={{textAlign:'justify'}}>Therefore, TU Group would like to explain the objectives of collecting, using and disclosing your Personal Data as follows:</p>
            <ul className="p-x-md">
                <li>To identify your identity;</li>
                <li>To create personal account or membership;</li>
                <li>To process payment;</li>
                <li>To deliver purchased product and tax invoice;</li>
                <li>To offer products and services;</li>
                <li>To conduct surveys related to products and services;</li>
                <li>To improve our websites to serve our user purposes;</li>
                <li>To be a communication and advertisement channel;</li>
                <li>To have security in designated place and area e.g., CCTV recording.</li>
                <li>To ensure compliance related to the implications of Data Protection Law, Public Health Law, Tax Law, Labour Law and other relevant laws;</li>
                <li>To establish, exercise or defend legal rights or claims as necessary;</li>
                <li>To comply with laws and regulations or lawful requests by public authorities or where otherwise required or permitted by applicable laws, court orders, government regulations, or regulatory authorities, whether regional or international;</li>
                <li>To pursue TU Group&rsquo;s legitimate interests, for example to prevent fraud or report potential crimes.</li>
            </ul>
            <p style={{textAlign:'justify'}}><strong>2. Collected Data</strong></p>
            <p style={{textAlign:'justify'}}>For objectives as specified above, TU Group may collect, use and disclose your Personal Data. The type of data collected will depend on the interaction between you and TU Group, including but not limited to the following information:</p>
            <ul className="p-x-md">
                <li>Personal details such as name, surname, photo, gender, birthday, ID number, passport number, and resident country;</li>
                <li>Contact information such as address, email, contact number;</li>
                <li>Payment information such as information related to credit/debit card numbers - including the name on card, card number, issuing bank, issuing country and expiration date;</li>
                <li>Technical information such as IP address, cookies, accessed devices, location while using the website;</li>
                <li>Purchase history;</li>
                <li>Satisfaction information as provided by you;</li>
                <li>Public social media information;</li>
                <li>Sensitive information provided by you such as your food allergies;</li>
                <li>Any other information you are willing to provide.</li>
            </ul>
            <p style={{textAlign:'justify'}}>TU Group&rsquo;s websites may link to third party websites to provide relevant references. TU Group is not responsible for such external content managed by third party websites, which may contain separate privacy policies and data processing disclosures. The link to a website is not an authorisation or representation of our affiliation with that third party. TU Group is not in a position to endorse or control privacy policy, security policy, cookies policy and data collection policy of the third parties.</p>
            <p style={{textAlign:'justify'}}><strong>3. Retention</strong></p>
            <p style={{textAlign:'justify'}}>Your personal information will be collected for as long as necessary to satisfy the purposes for which your personal data has been collected. For example, if you ordered our products through our online channel, TU Group will collect data related to your transaction and will keep such information as long as necessary to achieve the objective of that particular transaction, or if you subscribed to TU Group&rsquo;s newsletter, your information will be collected and used until you unsubscribe or notify us that you no longer want to receive the newsletter.</p>
            <p style={{textAlign:'justify'}}><strong>4. Consent</strong></p>
            <p style={{textAlign:'justify'}}>TU Group may acquire your consent to collect, use and disclose your Personal Data for the following purposes, which include but are not limited to:</p>
            <ul className="p-x-md">
                <li>Collect and use your sensitive Personal Data as necessary;</li>
                <li>Analyse your preferences and consumption behavior in order to forecast your preferences based on your profile analysis in order to offer you with targeted content and advertisements;</li>
                <li>If you are a minor, incompetent or quasi-incompetent person whose consent must be given by their parent, guardian or curator (as the case may be).</li>
                <li>Send or transfer your Personal Data to another country, which may have inadequate personal data protection standards.</li>
            </ul>
            <p style={{textAlign:'justify'}}>If it is necessary for compliance with the applicable laws, TU Group may process your personal data without your consent. In this regard, TU Group will strictly comply with the relevant laws regarding your personal data.</p>
            <p style={{textAlign:'justify'}}><strong>5. Disclosure of Personal Data</strong></p>
            <p style={{textAlign:'justify'}}>TU Group may be required to disclose your Personal Data internally and may need to disclose Personal Data to a third party, both within Thailand and in other countries, who have appropriate personal data protection standards. In this regard, TU Group will provide appropriate contract to protect your Personal Data:</p>
            <p style={{textAlign:'justify'}}>Types of third parties that may receive your information e.g.;</p>
            <ul className="p-x-md">
                <li>Contractual parties who provide TU Group&rsquo;s services such as:
                <ul>
                    <li>Data analysis service providers</li>
                    <li>Marketing agencies</li>
                    <li>Other agencies who operate or have an agreement with TU Group</li>
                </ul>
            </li>
            <li>Trade partners</li>
            <li>Certified authorities which TU Group has requested to be certified for its own benefit including other auditing agencies</li>
            <li>Any third party who has legitimate rights to access your Personal Data</li>
            </ul>
            <p style={{textAlign:'justify'}}><strong>6. Accessing website</strong></p>
            <p style={{textAlign:'justify'}}>TU Group is obliged under Thai law to retain your computer traffic data, for at least 90 days from the date you entered TU Group&rsquo;s website. However, TU Group will not use such data to analyze consumption behavior or conduct market research without your explicit consent.</p>
            <p style={{textAlign:'justify'}}><strong>7. Cookies</strong></p>
            <p style={{textAlign:'justify'}}>Cookies are small text files that are stored on your computer's browser or devices when you connect or visit any website to navigate you through our website, deliver user friendly and more personalized services to you. However, Cookies will not collect information stored on your computer (&ldquo;Cookies&rdquo;).</p>
            <p style={{textAlign:'justify'}}>TU Group uses Cookies to learn more about how you interact with the content on our website in order to give you more satisfaction when using our website. Cookies will remember the browser you used and installed while you are on our website. In addition, Cookies remember your preferences such as languages used and regions and automatically adjusts settings when you return to our website. Some of the Cookies are session Cookies and some are persistent Cookies which will be stored on your computer for a longer period.</p>
            <p style={{textAlign:'justify'}}>For certain operations, TU Group will use third party services provider, for example, to track and analyse statistical use and information of users of the TU Group website in order to customize our website according to your individual interests and manage content on the website.</p>
            <p style={{textAlign:'justify'}}>TU Group will not use Cookies to collect personal information. If you do not want to accept Cookies you may choose to decline Cookies or block Cookies sent by TU Group or third-party service providers by changing the settings in your browser (you can find more information in the Help menu of your browser). However, most browsers accept Cookies automatically. If you do not wish to accept cookies, you may have to constantly block or delete cookies.</p>
            <p style={{textAlign:'justify'}}><strong>8. Your Rights</strong></p>
            <p style={{textAlign:'justify'}}>As a Data Subject, you have the following rights:</p>
            <ul className="p-x-md">
            <li >To withdraw consent for the collection, use or disclose Personal Data;</li>
            <li>To request access to and obtain a copy of your Personal Data or to request the disclosure of the acquisition of your Personal Data obtained without consent;</li>
            <li>To request to obtain Personal Data and request the transfer of Personal Data to a third party if it can be done automatically;</li>
            <li>To object to the collection, use or disclosure of the Personal Data;</li>
            <li>To request to erase or destroy Personal Data, or anonymise the Personal Data to become anonymous;</li>
            <li>To request to restrict the use of Personal Data;</li>
            <li>To request to update, complete and not misleading of Personal Data collected.</li>
            </ul>
            <p style={{textAlign:'justify'}}>You can exercise your rights by sending TU Group an email or sending a written notice to TU Group, attaching a copy of your ID or equivalent details as requested. TU Group will process such information received in accordance with, and to the extent permitted, by applicable laws.</p>
            <p style={{textAlign:'justify'}}>Please note that TU Group shall retain our rights under the laws to reject your request in certain circumstances. If TU Group decides to reject your request, you will be notified of the reason for such rejection. TU Group will try our best, also with considering technical capabilities, to answer your request on how we process your Personal Data. However, if you have unresolved concerns, you can complain to TU Group or proceed further to the Data Protection Authorities.</p>
            <p style={{textAlign:'justify'}}><strong>9. Protection of Personal Data</strong></p>
            <p style={{textAlign:'justify'}}>TU Group is committed to protecting your Personal Data under our security standards, and will provide appropriate safeguards to protect your Personal Data in order to ensure that Personal Data will be legally and appropriately collected, used or disclosed.</p>
            <p style={{textAlign:'justify'}}><strong>10. Changes to Privacy Notice</strong></p>
            <p style={{textAlign:'justify'}}>TU Group reserves the right to make changes to this Notice to be appropriate and in accordance with any laws. Please frequently check to see any updates or changes to TU Group&rsquo;s Privacy Notice.</p>
            <p style={{textAlign:'justify'}}><strong>11. Contact</strong></p>
            <p style={{textAlign:'justify'}}>If you have any enquiries or would like to exercise your rights or need any help regarding your personal data, please send an email to: <a href="mailto:TU_Corporate@thaiunion.com">TU_Corporate@thaiunion.com</a> and provide us with your personal information together with evidence to verify your identity such as ID card. However, TU Group may ask for additional relevant documents or may reject your request if TU Group has received insufficient information.</p>

        </div>
    )
  }
}


export default Privacy;
