//import react
import React from "react";
import "./css/style.css";

//import bootstrap css
// import "bootstrap/dist/css/bootstrap.min.css";

import apk from "./apps/UpWISEForms.apk";

class Downloads extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			height: 0,
			width: 0,
		};

		window.addEventListener("resize", this.setScreenOrientation);
	}

	setScreenOrientation = () => {
		const height = this.divElement.clientHeight;
		const width = this.divElement.clientWidth;

		if (this.state.height !== height) {
			this.setState({ height, width });
		}
	};

	componentDidMount() {
		this.setScreenOrientation();
	}

	componentDidUpdate() {
		this.setScreenOrientation();
	}

	render() {
		return (
			<div
				ref={(divElement) => {
					this.divElement = divElement;
				}}
				style={{ height: "100%", width: "100%" }}
			>
				{/* BODY START */}
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						height: this.state.height - 50,
						width: "100%",
					}}
				>
					<div
						style={{
							display: "flex",
							flexWrap: "wrap",
							justifyContent: "center",
							alignItems: "center",
							marginBottom: 0,
						}}
					>
						<h2
							style={{
								display: "flex",
								textAlign: "center",
								fontWeight: "bold",
								color: "black",
								marginBottom: 0,
							}}
						>
							UpWISEForms
							<span style={{ fontSize: 14 }}>®</span>
						</h2>
						<h2
							style={{
								textAlign: "center",
								color: "black",
								marginLeft: 5,
								marginBottom: 0,
							}}
						>
							Professional Mobile Application
						</h2>
					</div>
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							marginTop: 10,
						}}
					>
						<h3
							style={{
								textAlign: "center",
								color: "black",
								fontWeight: "bold",
								marginTop: 0,
								marginBottom:
									this.state.width < 500
										? 0
										: this.state.height < 500
										? 0
										: 100,
							}}
						>
							App Setup Download Page
						</h3>
					</div>
					<div
						style={{
							display: "flex",
							justifyContent: "space-around",
							alignItems: "center",
							flexWrap: "wrap",
							maxWidth: 700,
							width: "100%",
						}}
					>
						{/* <!-- Downloads --> */}

						{/* <!-- APPLE --> */}
						<a
							className={"CustomBox"}
							href={"https://apps.apple.com/us/app/upwiseforms/id1635943407"}
							target="_blank"
						>
							<div style={{ height: "70%", marginTop: "15%" }}>
								<div style={{ position: "relative" }}>
									<img
										className="card-imgwise img-fluid"
										src={require("./img/iconApp.png")}
										alt=""
										style={{ width: 85, height: 85, margin: 0 }}
									/>

									<img
										className="card-imgwise img-fluid"
										src={require("./img/apple2.jpg")}
										alt=""
										style={{
											width: 35,
											height: 35,
											position: "absolute",
											right: -30,
											bottom: -15,
										}}
									/>
								</div>
							</div>
							<div style={{ height: "30%", display: "flex" }}>
								<p style={{ color: "black", fontWeight: "bold", fontSize: 16 }}>
									iOS
								</p>

								<p style={{ color: "black", marginLeft: 5, fontSize: 16 }}>
									Users Download
								</p>
							</div>
						</a>

						<a className={"CustomBox"} href={apk} download="UpWISEForms.apk">
							<div style={{ height: "70%", marginTop: "15%" }}>
								<div style={{ position: "relative" }}>
									<img
										className="card-imgwise img-fluid"
										src={require("./img/iconApp.png")}
										alt=""
										style={{ width: 85, height: 85, margin: 0 }}
									/>

									<img
										className="card-imgwise img-fluid"
										src={require("./img/android-icon2.png")}
										alt=""
										style={{
											width: 35,
											height: 35,
											position: "absolute",
											right: -30,
											bottom: -15,
											borderColor: "#0b59b2",
											borderWidth: 1,
											borderStyle: "solid",
										}}
									/>
								</div>
							</div>
							<div style={{ height: "30%", display: "flex" }}>
								<p style={{ color: "black", fontWeight: "bold", fontSize: 16 }}>
									Android
								</p>

								<p style={{ color: "black", marginLeft: 5, fontSize: 16 }}>
									Users Download
								</p>
							</div>
						</a>
					</div>
				</div>

				{/* <!-- Footer --> */}
				<div
					id="footer"
					className="container-fluid p-3 text-center"
					style={{
						position: "fixed",
						bottom: 0,
						display: "flex",
						flexWrap: "wrap",
						justifyContent: "center",
						alignItems: "center",
						width: "100%",
						height: 50,
						fontStyle: "italic",
					}}
				>
					<p
						className="mb-0"
						style={{ color: "black", margin: 0, fontSize: 16 }}
					>
						Copyright © 2022 -
						<span style={{ fontWeight: "bold", fontSize: 16, marginLeft: 5 }}>
							Thai Union Group
						</span>
					</p>
					<p
						className="mb-0"
						style={{
							color: "black",
							marginBottom: 0,
							fontSize: 16,
							marginLeft: 5,
						}}
					>
						- Engineered by
						<span style={{ fontWeight: "bold", marginLeft: 5 }}>
							Bitcliq Technologies, S.A.
						</span>
					</p>
				</div>
			</div>
		);
	}
}

export default Downloads;
