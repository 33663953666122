import axios from 'axios';
import { ErrorNotification, SuccessNotification } from "../utils/notifications"
export const getSites = async (SitesOnly) => {
    axios.defaults.withCredentials = true

    try {
        const localtoken = await localStorage.getItem('token')
        // console.log("getSites", localtoken);
        return axios.get(`${process.env.REACT_APP_API_URL}/ListSites`, {
            params: {
                token: localtoken,
                SitesOnly: SitesOnly
            }
        })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            return [];
        });
    } catch (error) {
        return [];
    }
};


export const addSite = async (site) => {
    axios.defaults.withCredentials = true

    const localtoken = await localStorage.getItem('token')
    return axios.post(`${process.env.REACT_APP_API_URL}/CreateSite`, {
        SiteName: site,
        token: localtoken
    })
    .then(response => {
        SuccessNotification("success", "Site "+site+" has been successfully created.");
        return response.data;
    })
    .catch(error => {
        ErrorNotification("error","Site Creation Failed");
        return "ERROR"+error;
    });
}

export const setSiteUsers = async (site, Users) => {
    axios.defaults.withCredentials = true

    const localtoken = await localStorage.getItem('token')
    return axios.post(`${process.env.REACT_APP_API_URL}/setSiteUsers`, {
        Users: Users,
        SiteID: site.id,
        token: localtoken
    })
    .then(response => {
        SuccessNotification("success", "Site "+site.name+" has been successfully updated.");
        return response.data;
    })
    .catch(error => {
        ErrorNotification("error","Site Creation Failed");
        return "ERROR"+error;
    });
}

export const updateSite = async (site, id) => {
    axios.defaults.withCredentials = true

    const localtoken = await localStorage.getItem('token')
    return axios.post(`${process.env.REACT_APP_API_URL}/UpdateSite`, {
        SiteName: site,
        SiteID: id,
        token: localtoken
    })
    .then(response => {
        SuccessNotification("success", "Site "+site+" has been successfully updated.");
        return response.data;
    })
    .catch(error => {
        ErrorNotification("error","Site Creation Failed");
        return "ERROR"+error;
    });
}


export const deleteSite = async (id) => {
    axios.defaults.withCredentials = true;

    const localtoken = await localStorage.getItem('token')
    return axios.post(`${process.env.REACT_APP_API_URL}/DeleteSite`, {
        SiteID: id,
        token: localtoken
    })
    .then(response => {
        
        SuccessNotification("success", "Site deleted successfully.");
        return response.data;
    })
    .catch(error => {
        ErrorNotification("error", "Error deleting site");
        return "ERROR"+error;
    });
}

export const getSiteInfo = async (id) => {

    axios.defaults.withCredentials = true
    try {
        const localtoken = await localStorage.getItem('token')
        // console.log("getSites", localtoken);
        return axios.get(`${process.env.REACT_APP_API_URL}/SiteInfo`, {
            params: {
                token: localtoken,
                SiteID: id
            }
        })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            return [];
        });
    } catch (error) {
        return [];
    }
}