let FormType = "";
let Category = {};
export const setFormType = (type) => {
  FormType = type;
};

export const getFormType = () => {
  return FormType;
};

export const setCategory = (category) => {
  Category = category;
};

export const getCategory = () => {
  return Category;
}