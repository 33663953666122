import React, { Component, forwardRef, createRef } from "react";
import Toggle from "react-toggle";
import { Checkbox, Radio, Switch } from "pretty-checkbox-react";
import ReactDOM from "react-dom";
import ReactStars from "react-rating-stars-component";
import DatePicker from "react-datepicker";
import Webcam from "react-webcam";
import { geolocated } from "react-geolocated";
import "react-datepicker/dist/react-datepicker.css";
import "react-toggle/style.css";

const DateCustomElement = forwardRef(({ value, onClick }, ref) => (
	<div
		onClick={onClick}
		ref={ref}
		style={{ width: "100%", textAlign: "center", paddingTop: 7 }}
	>
		{value}
	</div>
));

class FormGenerator extends Component {
	constructor(props) {
		super(props);
		this.state = {
			component: props.component,
			borderColor: "gray",
			checked: false,
			value: props.VarValue,
			date: Date.now(),
			index: props.index,
			showcamera: false,
		};

		this.webcamRef = createRef();
	}

	componentDidMount = async () => {
		if (
			this.state.component.type.toLowerCase() === "photocamera" ||
			this.state.component.type.toLowerCase() === "anyphoto"
		) {
			let x = (await navigator.mediaDevices.enumerateDevices()) ?? [];
			x = x.filter((obj) => {
				return obj.kind === "videoinput";
			});
			let rearCamera = x.find((obj) => {
				return (
					obj.label.toLowerCase().includes("back") ||
					obj.label.toLowerCase().includes("rear")
				);
			});
			// console.log("RearCamera", rearCamera);
			if (x.length > 0) {
				this.setState({
					cameras: x,
					selectedCamera: rearCamera ?? x.length > 1 ? x[1] : x[0],
				});
			}

			// console.log("cameras", this.state.cameras);
			// console.log("selectedCamera", this.state.selectedCamera);
		}
	};

	SwitchCamera = () => {
		if (this.state.cameras.length < 1) {
			return;
		}

		let selectedCameraIndex = this.state.cameras.findIndex((obj) => {
			return obj.deviceId === this.state.selectedCamera.deviceId;
		});
		// console.log("selectedCameraIndex", selectedCameraIndex);
		// console.log("cameras", this.state.cameras);

		if (selectedCameraIndex < this.state.cameras.length - 1) {
			selectedCameraIndex++;
		} else {
			selectedCameraIndex = 0;
		}

		this.setState({
			selectedCamera: this.state.cameras[selectedCameraIndex],
		});
	};

	componentDidUpdate = async () => {
		if (this.state.component.type === "GPS") {
			let result = "";
			try {
				if (this.props.isGeolocationAvailable) {
					if (this.props.isGeolocationEnabled && this.props.coords !== null) {
						result =
							this.props.coords.latitude + " " + this.props.coords.longitude;
					} else {
						result = "GPS permission denied";
					}
				} else {
					result = "GPS not supported";
				}
			} catch (error) {
				console.log("gps error", error);
			}

			if (this.state.value !== result) {
				this.ValueChanged(result);
			}
		}
	};

	handleFocusBack = () => {
		this.ChangeBorderColor(0);
		window.removeEventListener("focus", this.handleFocusBack);
	};

	TakePictureCamera = () => {
		let picture = this.webcamRef.current.getScreenshot();
		this.ValueChanged(picture);
	};

	ClickedFileInput = (e) => {
		e.target.value = null;
		window.addEventListener("focus", this.handleFocusBack);
	};

	ChangeSelectFile = (e) => {
		try {
			//    console.log("File!", this.state.index)
			const reader = new FileReader();
			reader.onload = () => {
				if (reader.readyState === 2) {
					this.ValueChanged(reader.result);
					// this.setState({
					//     value: reader.result
					// });
					// console.log("reader.result", reader.result);
				}
			};
			reader.readAsDataURL(e.target.files[0]);

			this.ChangeBorderColor(0);
		} catch (error) {
			console.log("error", error);
		}
	};

	checkboxState = (id) => {
		var aux = this.state.value;
		aux[id] = !aux[id];

		this.setState({
			value: aux,
		});

		console.log("aux", aux);
		this.props.CheckboxValueChangedMethod(aux);
	};

	ChangeBorderColor = (value) => {
		let color = "gray";

		if (value === 1) {
			color = "#0b59b2";
		}

		this.setState({
			borderColor: color,
			SelectedStyle: 1,
		});
	};

	returnValue = (value) => {
		// console.log("value -> " + value)
		this.props.ValueChangedMethod(this.state.index, value);
	};

	ValueChanged = (value) => {
		this.setState({ value: value });
		this.returnValue(value);
	};

	DateChanged = (date) => {
		this.setState({ date: date });
		this.returnValue(date);
	};

	render() {
		switch (this.state.component.type.toLowerCase()) {
			case "text": {
				return (
					<h2 style={{ textAlign: "center", color: "#0b59b2" }}>
						{this.state.component.placeholder}
					</h2>
				);
			}
			case "textinput": {
				return (
					<div
						style={{
							borderColor: this.state.borderColor,
							borderRadius: 10,
							width: 400,
							height: 40,
							display: "block",
							margin: "0 auto",
							borderWidth: 2,
							borderStyle: "solid",
						}}
					>
						<input
							className="FormInputStyleHover"
							style={{
								padding: 10,
								borderWidth: 0,
								backgroundColor: "transparent",
								height: 40,
								width: "100%",
								outlineWidth: 0,
							}}
							type="text"
							onFocus={() => this.ChangeBorderColor(1)}
							onBlur={() => this.ChangeBorderColor(0)}
							onChange={(e) => {
								this.returnValue(e.target.value);
								this.setState({ value: e.target.value });
							}}
							value={this.state.value}
							placeholder={
								this.state.component.placeholder +
								(this.state.component.mandatory ? " *" : "")
							}
							maxLength={40}
						/>
					</div>
				);
			}
			case "multitextinput": {
				return (
					<div
						style={{
							borderColor: this.state.borderColor,
							borderRadius: 10,
							width: 400,
							height: 120,
							display: "block",
							margin: "0 auto",
							borderWidth: 2,
							borderStyle: "solid",
						}}
					>
						<textarea
							className="FormInputStyleHover"
							style={{
								borderWidth: 0,
								backgroundColor: "transparent",
								height: 110,
								margin: 5,
								width: "97%",
								resize: "none",
								outlineWidth: 0,
							}}
							onFocus={() => this.ChangeBorderColor(1)}
							onBlur={() => this.ChangeBorderColor(0)}
							onChange={(e) => {
								this.returnValue(e.target.value);
								this.setState({ value: e.target.value });
							}}
							value={this.state.value}
							placeholder={
								this.state.component.placeholder +
								(this.state.component.mandatory ? " *" : "")
							}
						></textarea>
					</div>
				);
			}
			case "numberinput": {
				return (
					<div
						style={{
							borderColor: this.state.borderColor,
							borderRadius: 10,
							width: 400,
							height: 40,
							display: "block",
							margin: "0 auto",
							borderWidth: 2,
							borderStyle: "solid",
						}}
					>
						<input
							className="FormInputStyleHover"
							style={{
								padding: 10,
								borderWidth: 0,
								backgroundColor: "transparent",
								height: 40,
								width: "100%",
								outlineWidth: 0,
							}}
							type="number"
							onFocus={() => this.ChangeBorderColor(1)}
							onBlur={() => this.ChangeBorderColor(0)}
							onChange={(e) => {
								this.returnValue(e.target.value);
								this.setState({ value: e.target.value });
							}}
							value={this.state.value}
							placeholder={
								this.state.component.placeholder +
								(this.state.component.mandatory ? " *" : "")
							}
							maxLength={40}
						/>
					</div>
				);
			}
			case "select": {
				return (
					<div
						style={{
							width: 400,
							height: 80,
							display: "block",
							margin: "0 auto",
						}}
					>
						<p style={{ color: "#0b59b2", height: 20 }}>
							{this.state.component.placeholder +
								(this.state.component.mandatory ? "*" : "")}
						</p>
						<select
							style={{
								width: "100%",
								height: 40,
								borderRadius: 10,
								padding: 10,
								borderWidth: 2,
								outlineWidth: 0,
								borderColor: this.state.borderColor,
							}}
							onFocus={() => this.ChangeBorderColor(1)}
							onBlur={() => this.ChangeBorderColor(0)}
							onChange={(e) => {
								this.returnValue(e.target.value);
								this.setState({ value: e.target.value });
							}}
							value={this.state.value}
						>
							<option value="">Select an option</option>
							{this.state.component.options.map((option, index) => {
								// console.log("option->", option)
								return (
									<option key={option.id} value={option.id}>
										{option.value}
									</option>
								);
							})}
						</select>
					</div>
				);
			}
			case "switch": {
				return (
					<div
						style={{
							width: 400,
							display: "block",
							margin: "0 auto",
							display: "flex",
						}}
					>
						<div style={{ flex: 1 }}>
							<p style={{ color: "#0b59b2", height: 20 }}>
								{this.state.component.placeholder.split("|")[0]}
							</p>
							<p style={{ color: "#0b59b2", height: 20, fontSize: 12 }}>
								{this.state.component.placeholder.split("|")[1]}
							</p>
						</div>
						<div>
							{" "}
							{/* sem flex, ocupa apenas o espaço necessário para o conteudo. */}
							<Toggle
								defaultChecked={this.state.checked}
								value={this.state.checked + ""}
								icons={false}
								onChange={() => this.setState({ checked: !this.state.checked })}
							/>
						</div>
					</div>
				);
			}
			case "checkbox": {
				return (
					<div style={{ width: 400, display: "block", margin: "0 auto" }}>
						<p style={{ color: "#0b59b2", height: 20 }}>
							{this.state.component.placeholder +
								(this.state.component.mandatory ? " *" : "")}
						</p>
						{this.state.component.options.map((option, index) => {
							return (
								<div
									key={"div_" + option.id}
									style={{ width: "100%", display: "flex", marginTop: 20 }}
								>
									<Checkbox
										// onChange={()=> {this.setState({checked:!this.state.checked})}}

										onChange={(value) => this.checkboxState(option.id)}
										key={option.id}
										value={this.state.value[option.id]}
										shape="round"
										color="success-o"
										bigger
									>
										{option.value}
									</Checkbox>
								</div>
							);
						})}
					</div>
				);
			}
			case "rating": {
				return (
					<div style={{ width: 400, display: "block", margin: "0 auto" }}>
						<p style={{ color: "#0b59b2", height: 20 }}>
							{this.state.component.placeholder +
								(this.state.component.mandatory ? " *" : "")}
						</p>
						{
							<div style={{ letterSpacing: 10 }}>
								<ReactStars
									count={parseInt(this.state.component.quantity ?? 5)}
									onChange={(value) => {
										this.ValueChanged(parseInt(value));
									}}
									value={parseInt(this.state.value ?? 0)}
									size={24}
									activeColor="#ffd700"
								/>
							</div>
						}
					</div>
				);
			}
			case "datepicker": {
				return (
					<div style={{ width: 400, display: "block", margin: "0 auto" }}>
						<p style={{ color: "#0b59b2", height: 20 }}>
							{this.state.component.placeholder +
								(this.state.component.mandatory ? " *" : "")}
						</p>
						<div
							style={{
								borderColor: this.state.borderColor,
								position: "relative",
								borderRadius: 10,
								width: 400,
								height: 40,
								display: "block",
								borderWidth: 2,
								borderStyle: "solid",
							}}
						>
							<DatePicker
								selected={this.state.date}
								onChange={(date) => {
									this.DateChanged(date);
								}}
								dateFormat="dd/MMM/yyyy"
								customInput={<DateCustomElement></DateCustomElement>}
								onCalendarOpen={() => {
									this.ChangeBorderColor(1);
								}}
								onCalendarClose={() => {
									this.ChangeBorderColor(0);
								}}
							/>
						</div>
					</div>
				);
			}
			case "timepicker": {
				return (
					<div style={{ width: 400, display: "block", margin: "0 auto" }}>
						<p style={{ color: "#0b59b2", height: 20 }}>
							{this.state.component.placeholder +
								(this.state.component.mandatory ? " *" : "")}
						</p>
						<div
							style={{
								borderColor: this.state.borderColor,
								position: "relative",
								borderRadius: 10,
								width: 400,
								height: 40,
								display: "block",
								borderWidth: 2,
								borderStyle: "solid",
							}}
						>
							<DatePicker
								selected={this.state.date}
								onChange={(date) => {
									this.DateChanged(date);
								}}
								showTimeSelect={true}
								showTimeSelectOnly={true}
								dateFormat="HH:mm"
								timeIntervals={1}
								customInput={<DateCustomElement></DateCustomElement>}
								onCalendarOpen={() => {
									this.ChangeBorderColor(1);
								}}
								onCalendarClose={() => {
									this.ChangeBorderColor(0);
								}}
							/>
						</div>
					</div>
				);
			}
			case "datetimepicker": {
				return (
					<div style={{ width: 400, display: "block", margin: "0 auto" }}>
						<p style={{ color: "#0b59b2", height: 20 }}>
							{this.state.component.placeholder +
								(this.state.component.mandatory ? " *" : "")}
						</p>
						<div
							style={{
								borderColor: this.state.borderColor,
								position: "relative",
								borderRadius: 10,
								width: 400,
								height: 40,
								display: "block",
								borderWidth: 2,
								borderStyle: "solid",
							}}
						>
							<DatePicker
								selected={this.state.date}
								onChange={(date) => {
									this.DateChanged(date);
								}}
								dateFormat="dd/MMM/yyyy HH:mm"
								showTimeSelect={true}
								timeIntervals={1}
								customInput={<DateCustomElement></DateCustomElement>}
								onCalendarOpen={() => {
									this.ChangeBorderColor(1);
								}}
								onCalendarClose={() => {
									this.ChangeBorderColor(0);
								}}
							/>
						</div>
					</div>
				);
			}
			case "photocamera": {
				return (
					<div style={{ width: 400, display: "block", margin: "0 auto" }}>
						<p style={{ color: "#0b59b2", height: 20 }}>
							{this.state.component.placeholder}
						</p>
						{this.state.value !== null &&
						this.state.value !== undefined &&
						this.state.value !== "" ? (
							<img src={this.state.value} width="400"></img>
						) : (
							<>
								{this.state.cameras?.length > 1 ? (
									<div
										style={{
											width: 400,
											textAlign: "center",
											display: "block",
										}}
									>
										<button
											className="btn btn-primary"
											style={{ marginBottom: 5 }}
											onClick={() => {
												this.SwitchCamera();
											}}
										>
											Switch Cameras
										</button>
									</div>
								) : null}
								{this.state.cameras?.length > 0 ? (
									<div
										style={{
											width: 400,
											textAlign: "center",
											display: "block",
										}}
									>
										<button
											className="btn btn-info"
											style={{ marginBottom: 5 }}
											onClick={() => {
												this.setState({ showcamera: !this.state.showcamera });
											}}
										>
											{this.state.showcamera ? "Hide Camera" : "Show Camera"}
										</button>
									</div>
								) : null}
								{/* <p>{this.state.selectedCamera?.deviceId + " <> "  }</p> */}
								{this.state.showcamera ? (
									<Webcam
										audio={false}
										videoConstraints={{
											deviceId: this.state.selectedCamera?.deviceId,
										}}
										ref={this.webcamRef}
										width={400}
									/>
								) : null}
							</>
						)}
						{this.state.value !== null &&
						this.state.value !== undefined &&
						this.state.value !== "" ? (
							<div
								style={{
									width: 400,
									display: "block",
									height: 100,
									margin: "0 auto",
								}}
							>
								<div
									style={{
										borderRadius: 10,
										width: 400,
										height: 40,
										display: "flex",
									}}
								>
									<button
										className="btn btn-danger"
										style={{ width: 400, height: 40, marginTop: 10 }}
										onClick={() => {
											this.ChangeBorderColor(1);
											this.ValueChanged("");
											this.ChangeBorderColor(0);
										}}
									>
										DELETE
									</button>
								</div>
							</div>
						) : this.state.showcamera ? (
							<div
								style={{
									width: 50,
									display: "block",
									height: 100,
									margin: "0 auto",
								}}
							>
								<div
									style={{
										borderRadius: 100,
										width: 50,
										height: 50,
										display: "flex",
									}}
								>
									<button
										className="btn btn-primary"
										style={{
											width: 50,
											height: 50,
											borderRadius: 100,
											borderColor: this.state.borderColor,
											borderWidth: 2,
											borderStyle: "solid",
										}}
										onClick={() => {
											this.ChangeBorderColor(1);
											this.TakePictureCamera();
											this.ChangeBorderColor(0);
										}}
									>
										{" "}
									</button>
								</div>
							</div>
						) : null}
					</div>
				);
			}
			case "photo": {
				return (
					<div style={{ width: 400, display: "block", margin: "0 auto" }}>
						<p style={{ color: "#0b59b2", height: 20 }}>
							{this.state.component.placeholder +
								(this.state.component.mandatory ? " *" : "")}
						</p>
						{this.state.value !== "" ? (
							<div
								style={{ cursor: "pointer", height: 40, width: "100%" }}
								onClick={() => {
									this.ChangeBorderColor(1);
									this.ValueChanged("");
									this.ChangeBorderColor(0);
								}}
							>
								<div
									style={{
										borderColor: this.state.borderColor,
										borderRadius: 10,
										width: 400,
										height: 40,
										display: "flex",
										borderWidth: 2,
										borderStyle: "solid",
									}}
								>
									<p
										style={{
											alignSelf: "center",
											flex: 1,
											textAlign: "center",
											marginTop: 20,
										}}
									>
										{"Delete"}
									</p>
								</div>
							</div>
						) : (
							<label
								htmlFor={"PhotoUploader_" + this.state.index}
								style={{ cursor: "pointer", height: 40, width: "100%" }}
								onClick={() => this.ChangeBorderColor(1)}
							>
								<div
									style={{
										borderColor: this.state.borderColor,
										borderRadius: 10,
										width: 400,
										height: 40,
										display: "flex",
										borderWidth: 2,
										borderStyle: "solid",
									}}
								>
									<p
										style={{
											alignSelf: "center",
											flex: 1,
											textAlign: "center",
											marginTop: 20,
										}}
									>
										{"Upload a photo"}
									</p>
								</div>
							</label>
						)}

						<input
							className="FormInputStyleHover"
							style={{ opacity: 0, position: "absolute", zIndex: -1 }}
							type="file"
							name={"PhotoUploader_" + this.state.index}
							id={"PhotoUploader_" + this.state.index}
							onClick={(e) => {
								this.ClickedFileInput(e);
							}}
							onChange={this.ChangeSelectFile}
							maxLength={40}
							accept="image/*"
						/>

						{this.state.value !== null && this.state.value !== undefined ? (
							<img src={this.state.value} width="400"></img>
						) : null}
					</div>
				);
			}
			case "anyphoto": {
				return (
					<div style={{ width: 400, display: "block", margin: "0 auto" }}>
						<p style={{ color: "#0b59b2", height: 20 }}>
							{this.state.component.placeholder +
								(this.state.component.mandatory ? " *" : "")}
						</p>
						{this.state.value !== "" ? (
							<div
								style={{
									width: 400,
									display: "block",
									height: 100,
									margin: "0 auto",
								}}
							>
								<div
									style={{
										borderRadius: 10,
										width: 400,
										height: 40,
										display: "flex",
									}}
								>
									<button
										className="btn btn-danger"
										style={{ width: 400, height: 40, marginTop: 10 }}
										onClick={() => {
											this.ChangeBorderColor(1);
											this.ValueChanged("");
											this.ChangeBorderColor(0);
										}}
									>
										DELETE
									</button>
								</div>
							</div>
						) : (
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									marginBottom: 5,
								}}
							>
								<label
									htmlFor={"PhotoUploader_" + this.state.index}
									style={{ cursor: "pointer", height: 40, flex: 1 }}
									onClick={() => this.ChangeBorderColor(1)}
								>
									<div
										style={{
											borderColor: this.state.borderColor,
											borderRadius: 10,
											flex: 1,
											height: 40,
											display: "flex",
											borderWidth: 2,
											borderStyle: "solid",
										}}
									>
										<p
											style={{
												alignSelf: "center",
												flex: 1,
												textAlign: "center",
												marginTop: 20,
											}}
										>
											{"Upload a photo"}
										</p>
									</div>
								</label>
								<div style={{ flex: 0.1 }}></div>
								<div
									style={{
										borderRadius: 10,
										flex: 1,
										height: 40,
										display: "flex",
										borderWidth: 2,
										borderStyle: "solid",
										borderColor:
											this.state.SelectedStyle === 2 ? "#0b59b2" : "gray",
									}}
									onClick={() => {
										this.setState({
											SelectedStyle: this.state.SelectedStyle === 2 ? 1 : 2,
										});
									}}
								>
									<p
										style={{
											alignSelf: "center",
											flex: 1,
											textAlign: "center",
											marginTop: 20,
										}}
									>
										{"Camera"}
									</p>
								</div>
							</div>
						)}

						{this.state.SelectedStyle === 2 && this.state.value === "" ? (
							<>
								{this.state.cameras?.length > 1 ? (
									<div
										style={{
											width: 400,
											textAlign: "center",
											display: "block",
										}}
									>
										<button
											className="btn btn-primary"
											style={{ marginBottom: 5 }}
											onClick={() => {
												this.SwitchCamera();
											}}
										>
											Switch Cameras
										</button>
									</div>
								) : null}
								<Webcam
									audio={false}
									ref={this.webcamRef}
									videoConstraints={{
										deviceId: this.state.selectedCamera?.deviceId,
									}}
									width={400}
								/>
								<div
									style={{
										width: 50,
										display: "block",
										height: 100,
										margin: "0 auto",
									}}
								>
									<div
										style={{
											borderRadius: 100,
											width: 50,
											height: 50,
											display: "flex",
										}}
									>
										<button
											className="btn btn-primary"
											style={{
												width: 50,
												height: 50,
												borderRadius: 100,
												borderColor: this.state.borderColor,
												borderWidth: 2,
												borderStyle: "solid",
											}}
											onClick={() => {
												this.ChangeBorderColor(1);
												this.TakePictureCamera();
												this.ChangeBorderColor(0);
											}}
										>
											{" "}
										</button>
									</div>
								</div>
							</>
						) : null}

						<input
							className="FormInputStyleHover"
							style={{ opacity: 0, position: "absolute", zIndex: -1 }}
							type="file"
							name={"PhotoUploader_" + this.state.index}
							id={"PhotoUploader_" + this.state.index}
							onClick={(e) => {
								this.ClickedFileInput(e);
							}}
							onChange={this.ChangeSelectFile}
							maxLength={40}
							accept="image/*"
						/>

						{this.state.value !== null && this.state.value !== undefined ? (
							<img src={this.state.value} width="400"></img>
						) : null}
					</div>
				);
			}
			case "gps": {
				return (
					<div style={{ width: 400, display: "flex", margin: "0 auto" }}>
						<p style={{ color: "#0b59b2", flex: 1 }}>{"GPS"}</p>
						<p>{this.state.value ?? "Loading GPS..."}</p>
					</div>
				);
			}
			default: {
				// console.log(this.state.component.type, this.state.component.type.toLowerCase() === "checkbox");
				return null;
				return (
					<p style={{ textAlign: "center" }}>{this.state.component.type}</p>
				);
			}
		}
	}
}

export default geolocated({
	positionOptions: {
		enableHighAccuracy: false,
	},
	userDecisionTimeout: 5000,
})(FormGenerator);
