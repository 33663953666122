//import react
import React from 'react';
import {setFormType, getFormType, getSite, setSite} from "../../utils/sitesForm"
import { getSiteCategoriesTemplates } from "../../api/templates"
import {getSiteCategories} from "../../api/categories"
import { ErrorNotification, SuccessNotification } from "../../utils/notifications"
import ListFormAnswers from "./ListFormAnswers"
//import fontawesome
import { FaExpandArrowsAlt } from "react-icons/fa"
import { ImShrink } from "react-icons/im"

class SiteCategoryTemplates extends React.Component{
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
            site: this.props.Site,
			category: this.props.Category,
			templates: [],
			SelectedForm: null,
			ShowFormAnswers: false,
			TemplateCodeFilter: '',
			AnswersBiggerThanFilter: ''
		}
	}

	componentDidUpdate = () => {
		if(this.state.loading)
			this.LoadAll();
	}

	componentDidMount = () => {
		if(this.state.loading)
			this.LoadAll();
	}

	LoadAll = async () => {
		try {
			// console.log(this.state.site.id, this.state.category.id);
            let templates = await getSiteCategoriesTemplates(this.state.site.id, this.state.category.id);


			console.log(templates);

			var TemplateNamesCodes = [];
			for(var i = 0; i < templates.length; i++){
				
				var NameCode = {name: templates[i].templateCode, open: false};

				//if namecode.name exists in templatenamescode
				if(!TemplateNamesCodes.find(x => x.name === NameCode.name)){
					TemplateNamesCodes.push(NameCode);
				}
				
				// TemplateNamesCodes.push()
			}
			
			
			this.setState({
				loading: false,
				templates: templates,
				TemplateNamesCodes: TemplateNamesCodes
			});
			// console.log("sites...")
		} catch (error) {
			console.log("Erro!", error)
		}
	}

	CloseForm = async () => {
		this.setState({
			ShowForm: false,
			loading: true
		});
		this.LoadAll();
	}

	SelectedFormChanged = async (templateID) => {
		// console.log(templateID);
		let form = this.findTemplateByID(templateID);
		// console.log(form);
		this.setState({
			SelectedForm: form
		})
		
	}

	findTemplateByID = (templateid) => {
		return this.state.templates.find(template => template.id === templateid);
	}

	OpenFormAnswers = () => {
		this.setState({
			ShowFormAnswers: true
		})
	}

	ToggleGroup = (index) => {
		let TemplateNamesCodes = this.state.TemplateNamesCodes;
		TemplateNamesCodes[index].open = !TemplateNamesCodes[index].open;
		this.setState({
			TemplateNamesCodes: TemplateNamesCodes
		})
	}
	
	CloseFormAnswers = () => {
		this.setState({
			ShowFormAnswers: false
		})
	}

	onCodeFilterChanged = (e) => {
		this.setState({
			TemplateCodeFilter: e
		})
	}

	onAnswersBiggerThanFilter = (e) => {
		this.setState({
			AnswersBiggerThanFilter: e
		})
	}

    render(){
		if(this.state.loading)
			return null;

		if(this.state.ShowFormAnswers)
		{
			return (
				<React.Fragment>
					<ListFormAnswers CloseForm={this.CloseFormAnswers} Site={this.state.site} Category={this.state.category} Template={this.state.SelectedForm}></ListFormAnswers>
				</React.Fragment>
			)
		}
		
        return (
            <React.Fragment>
                <h2 className="mb-4">{this.state.site.name + " "} <i className="entypo-right"></i> {" " + this.state.category.name}</h2>
                <div style={{display:'flex', marginBottom: 5}}>
                    <button key={"GoBack"} type="button" className="btn" onClick={this.props.CloseForm}>
						<i className="entypo-back"></i> Go Back
                    </button>
					<button key={"Refresh"} type="button" className="btn" style={{marginLeft:10}} onClick={()=>  {this.setState({loading: true});}}>
						<i className="fa fa-refresh"></i> Refresh
                    </button>
                </div>
				<div className="align-items-center mb-4" style={{display:'flex'}}>
					<div style={{flex: 1, maxWidth: 200, minWidth: 100}}>
						<input
							type={"text"}
							className={"form-control"}
							placeholder="Filter by Code"
							onChange={(e) => this.onCodeFilterChanged(e.target.value)}
							value={this.state.TemplateCodeFilter}
						/>
					</div>
					<div style={{flex: 1, maxWidth: 250, minWidth: 100, marginLeft: 5}}>
						<input
							type={"number"}
							className={"form-control"}
							placeholder="Filter By Answer Amount"
							onChange={(e) => this.onAnswersBiggerThanFilter(e.target.value)}
							min={0}
							value={this.state.AnswersBiggerThanFilter}
						/>
					</div>
				</div>
                <div className="p-0" style={{borderBottom: 0, borderLeft: 0, borderRight: 0, height: '85%'}}>
                    <div className="d-flex flex-column justify-content-between align-items-center" style={{height: '100%'}}>
                        <div className="w-100" style={{overflowy: 'auto'}}>
						<table className='table' key={"table"}>
								<thead>
									<tr>
										<th style={{fontSize: 20, fontWeight: 500}} scope='col'>{"Template Code"}</th>
										<th scope='col' style={{textAlign: 'right', fontSize: 20, fontWeight: 500}} >{"Open"}</th>
									</tr>
								</thead>
								<tbody>
								{

									this.state.TemplateNamesCodes.map((group, index) => {
										// console.log(template);

										if(this.state.TemplateCodeFilter !== '' && !group.name.includes(this.state.TemplateCodeFilter)){
											return null;
										}

										return (
											<>
												<tr style={{verticalAlign: 'middle', minHeight: 50, height: 50}} key={"formgrouprow_"+index} onDoubleClick={()=>this.ToggleGroup(index)}>
												{/* <div key={"Template_"+index} className={`d-flex align-items-center p-3 custom-border-bottom`} onDoubleClick={()=>this.OpenFormAnswers()} onClick={() => this.SelectedFormChanged(template.id)}> */}
													<td><h5 className="mb-0">{group.name}</h5></td>
													<td style={{textAlign: 'right'}}>
														<button key={"cat_"+index} type="button" className="btn btn-esip-blue delete-btn me-2" style={{ margin: "auto" }} onClick={()=>this.ToggleGroup(index)}>
															{
																group.open ?
																<ImShrink />
																:
																<FaExpandArrowsAlt />
															}
														</button>
													</td>
												</tr>
												{
													group.open ?
													<tr>
														<td colSpan={2}>
															<div style={{width:'85%', margin:'0 auto'}}>
																<table className='table' key={"table2_"+index}>
																	<thead>
																		<tr>
																			<th style={{fontSize: 20, fontWeight: 500}} scope='col'>{"Template Name"}</th>
																			<th style={{fontSize: 20, fontWeight: 500}} scope='col'>{"Version"}</th>
																			<th style={{fontSize: 20, fontWeight: 500}} scope='col'>{"Answers"}</th>
																			<th scope='col' style={{textAlign: 'right', fontSize: 20, fontWeight: 500}} >{"Check Forms"}</th>
																		</tr>
																	</thead>
																	<tbody>
																	{

																		this.state.templates.reverse().map((template, index) => {

																			if(template.templateCode !== group.name){
																				return null;
																			}
																			
																			if(this.state.AnswersBiggerThanFilter !== '' && template.AnswersTemplate.length < this.state.AnswersBiggerThanFilter){
																				return null;
																			}

																			return (
																				<tr style={{verticalAlign: 'middle', minHeight: 50, height: 50}} key={"formexpandrow_"+template.id+"_"+index} onDoubleClick={()=>this.OpenFormAnswers()} onClick={() => this.SelectedFormChanged(template.id)}>
																					<td><h5 className="mb-0">{template.name}</h5></td>
																					<td><h5 className="mb-0">{template.version}</h5></td>
																					<td><h5 className="mb-0">{template.AnswersTemplate.length}</h5></td>
																					<td style={{textAlign: 'right'}}>
																						<button key={"editcategory_"+template.id} type="button" className="btn btn-esip-blue delete-btn me-2" style={{ margin: "auto" }} onClick={()=>this.OpenFormAnswers()}>
																							<i className="entypo-right-open-big"></i>
																						</button>
																					</td>
																				{/* </div> */}
																				</tr>
																			)
																		})
																	}
																	</tbody>
																</table>
															</div>
														</td>
													</tr>:null
												}
											</>
										)
									})
								}
								</tbody>
							</table>
                        </div>
                    </div>
                </div>			
				
            </React.Fragment >
        )
    }
}

export default SiteCategoryTemplates;