//import react
import React from "react";
import {
	setFormType,
	getFormType,
	getUser,
	setUser,
} from "../../utils/usersForm.js";
import {
	UserTemplatePermissions,
	UpdateUserTemplatePermissions,
} from "../../api/users";
import {
	ErrorNotification,
	SuccessNotification,
} from "../../utils/notifications";
import Multiselect from "multiselect-react-dropdown";

class FormTemplatePermissions extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			user: null,
			permissions: [],
			SitePermissions: null,
		};
	}

	componentDidUpdate = () => {
		if (this.state.loading) this.LoadAll();

		var script = document.getElementById("edit-script-modal-sites");

		if (script) {
			console.log("remove script!");
			script.remove();
		}
		var scriptx = document.createElement("script");
		scriptx.id = "edit-script-modal-sites";
		scriptx.innerHTML =
			"jQuery('#modal-6').modal('show', {backdrop: 'static'});";
		document.body.appendChild(scriptx);
	};

	componentDidMount = () => {
		if (this.state.loading) this.LoadAll();
	};

	LoadAll = async () => {
		var user = getUser();
		var permissions = await UserTemplatePermissions(user.id);

		let SitePermissions = [];
		user.defaultSite.map((site, index) => {
			return (SitePermissions[index] = true);
		});

		this.setState({
			loading: false,
			user: user,
			permissions: permissions,
			SitePermissions: SitePermissions,
		});
	};

	Submit = async () => {
		let result = await UpdateUserTemplatePermissions(
			this.state.permissions,
			this.state.user.id
		);

		if (result === []) return;

		this.CloseForm();
	};

	CloseForm = async () => {
		//reset state
		this.setState({
			loading: true,
			site: {},
			FormType: "", // "Create" or "Edit"
		});

		this.props.CloseForm();
	};

	PermissionChanged = (templateID, siteID) => {
		var permissions = this.state.permissions;
		var x = permissions.find((obj) => {
			return obj.templateID === templateID && obj.siteID === siteID;
		});
		if (x === undefined) {
			permissions.push({
				templateID: templateID,
				siteID: siteID,
			});
		} else {
			var index = permissions.indexOf(x);
			permissions.splice(index, 1);
		}
		this.setState({
			permissions: permissions,
		});
	};

	render() {
		if (this.state.loading) {
			return null;
		}

		return (
			<div className="modal fade" id="modal-6" data-backdrop="static">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<button
								type="button"
								className="close"
								data-dismiss="modal"
								aria-hidden="true"
								onClick={this.CloseForm}
							></button>
							<h4 className="modal-title">
								{this.state.user.username} - Permissions
							</h4>
						</div>
						<div className="modal-body custom-light-gray">
							{/* TODO... checkboxes com os sites para se preencher permissoes de um site de uma vez.
                            {
                                this.state.user.defaultSite.map((site, index) => {
                                    return (
                                        <div className="btn mx-1 my-2">
                                            <label key={"btnSitePermission_"+site.id} type="button">{site.name}</label>
                                            <input key={"cbSitePermission_"+site.id} id={"cbSitePermission_"+site.id} type={"checkbox"} checked={this.state.SitePermissions[index]} style={{transform:'scale(2)', marginLeft:15}}></input>
                                        </div>
                                    )
                                })
                            } */}
							<table className="table table-bordered">
								<thead>
									<tr>
										<th scope="col" style={{ textAlign: "center" }}>
											Site
										</th>
										{/* <th scope="col" style={{textAlign:'center'}}>Category</th> */}
										<th scope="col" style={{ textAlign: "center" }}>
											Form
										</th>
										<th scope="col" style={{ textAlign: "center" }}>
											Permission
										</th>
									</tr>
								</thead>
								<tbody>
									{
										//para cada site
										this.state.user.defaultSite.map((site, index) => {
											var lastTemplateCode = "";
											return (
												//para cada template no site
												site.templates.map((templates, templatesIndex) => {
													//Remover versoes antigas...
													if (lastTemplateCode === templates.templateCode) {
														return null;
													}

													lastTemplateCode = templates.templateCode;

													//se não houver categorias no tempalte temos o default... (se o user tiver acesso)
													if (templates.categories.length === 0) {
														var x = this.state.user.categories.find(
															(category) => {
																return category.name === "Default";
															}
														);
														if (x === null || x === undefined) {
															return null;
														}
													} else {
														var filter = templates.categories.filter(
															(category) => {
																var y = this.state.user.categories.find(
																	(obj2) => {
																		return obj2.id + "" === category.id + "";
																	}
																);
																return y !== undefined;
															}
														);

														if (filter.length === 0) {
															return null;
														}
													}

													return (
														<tr key={templatesIndex}>
															<td style={{ textAlign: "center" }}>
																{site.name}
															</td>
															{/* <td style={{textAlign:'center'}}>{templates.category}</td> */}
															<td style={{ textAlign: "center" }}>
																{templates.name}
															</td>
															<td style={{ textAlign: "center" }}>
																{" "}
																<input
																	type={"checkbox"}
																	style={{ transform: "scale(2)" }}
																	onChange={() =>
																		this.PermissionChanged(
																			templates.id,
																			site.id
																		)
																	}
																	checked={
																		this.state.permissions.find((obj) => {
																			return (
																				obj.templateID === templates.id &&
																				obj.siteID === site.id
																			);
																		}) !== undefined
																	}
																></input>{" "}
															</td>
														</tr>
													);
												})
											);
										})
									}
								</tbody>
							</table>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-esip-blue py-2 px-4"
								data-dismiss="modal"
								onClick={this.Submit}
							>
								Save
							</button>
							<button
								type="button"
								className="btn btn-secondary py-2 px-4"
								data-dismiss="modal"
								onClick={this.CloseForm}
							>
								Close
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default FormTemplatePermissions;
