let FormType = "";
let User = {};
export const setFormType = (type) => {
  FormType = type;
};

export const getFormType = () => {
  return FormType;
};

export const setUser = (user) => {
    User = user;
};

export const getUser = () => {
  return User;
}