//import react
import React from "react";
import { getUser } from "../../utils/usersForm";
import { deleteUser } from "../../api/users";

class DeleteForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			user: {},
		};
	}

	componentDidMount = () => {
		if (this.state.loading) this.LoadAll();
	};

	componentWillUnmount = () => {
		window.jQuery(".modal-backdrop").remove();
	};

	componentDidUpdate = () => {
		window.jQuery("#modal-6").modal("show", { backdrop: "static" });
	};

	LoadAll = async () => {
		var form = await getUser();

		this.setState({
			loading: false,
			user: form,
		});

		// console.log(this.state.user);
	};

	CloseForm = async () => {
		//reset state
		this.setState({
			loading: true,
			site: {},
		});

		this.props.CloseForm();
	};

	Delete = async () => {
		// console.log("this.state.user.id", this.state.user.id);
		await deleteUser(this.state.user.id);

		this.CloseForm();
	};

	render() {
		return this.state.loading ? null : (
			<div
				className="modal fade"
				id="modal-6"
				tabIndex={-1}
				data-backdrop="static"
			>
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<button
								type="button"
								className="close"
								data-dismiss="modal"
								aria-hidden="true"
								onClick={() => this.CloseForm()}
							>
								&times;
							</button>
							<h4 className="modal-title">Delete user?</h4>
						</div>
						<div className="modal-body custom-light-gray">
							<div className="mb-3">
								<label htmlFor="Username" className="form-label">
									Username:
								</label>
								<input
									type="text"
									className="form-control"
									id="Username"
									placeholder="Username"
									value={this.state.user.username}
									disabled
								/>
							</div>
							<div className="mb-3">
								<label htmlFor="Email" className="form-label">
									Email:
								</label>
								<input
									type="email"
									className="form-control"
									id="Email"
									placeholder="Email"
									value={this.state.user.email}
									disabled
								/>
							</div>
							<div
								className="mb-0"
								style={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "center",
								}}
							>
								<div>
									<label htmlFor="IsAdmin" className="form-label">
										Administrador:
									</label>
									<input
										type="checkbox"
										className="form-check-input"
										id="IsAdmin"
										checked={this.state.user.isAdmin}
										disabled
									/>
								</div>
								<div>
									<label
										htmlFor="IsSuperUser"
										className="form-label"
										style={{ marginLeft: "20px" }}
									>
										SuperUser:
									</label>
									<input
										type="checkbox"
										className="form-check-input"
										id="IsSuperUser"
										checked={this.state.user.isSuperUser}
										disabled
									/>
								</div>
								<div>
									<label
										htmlFor="IsActive"
										className="form-label"
										style={{ marginLeft: "20px" }}
									>
										Active:
									</label>
									<input
										type="checkbox"
										className="form-check-input"
										id="IsActive"
										checked={this.state.user.isActive}
										disabled
									/>
								</div>
								<div>
									<label
										htmlFor="IsBKOUser"
										className="form-label"
										style={{ marginLeft: "20px" }}
									>
										Backoffice User:
									</label>
									<input
										type="checkbox"
										className="form-check-input"
										id="IsBKOUser"
										checked={this.state.user.isBKOUser}
										disabled
									/>
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-danger py-2 px-4"
								data-bs-dismiss="modal"
								onClick={() => this.Delete()}
							>
								Delete
							</button>
							<button
								type="button"
								className="btn btn-secondary py-2 px-4"
								data-bs-dismiss="modal"
								onClick={() => this.CloseForm()}
							>
								Close
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default DeleteForm;
