//import react
import React from "react";
import { getCategory } from "../../utils/categoriesForm";
import { deleteCategory } from "../../api/categories";

class DeleteForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			category: {},
		};
	}

	componentDidMount = () => {
		// console.log("mount delete");
		this.LoadAll();
	};

	componentDidUpdate = () => {
		var script = document.getElementById("edit-script-modal-sites");

		if (script) {
			script.remove();
		}
		var scriptx = document.createElement("script");
		scriptx.id = "edit-script-modal-sites";
		scriptx.innerHTML =
			"jQuery('#modal-6').modal('show', {backdrop: 'static'});";
		document.body.appendChild(scriptx);
	};

	LoadAll = async () => {
		var form = await getCategory();
		// console.log("load delete category", form);
		// console.log(form.name);
		this.setState({
			loading: false,
			category: form,
		});

		// console.log(this.state.loading || this.category === {} || this.category === undefined);
		// console.log(this.state.loading);
		// console.log(this.category === {});
		// console.log(this.category === undefined);
	};

	CloseForm = async () => {
		//reset state
		this.setState({
			loading: true,
			site: {},
		});

		this.props.CloseForm();
	};

	Delete = async () => {
		await deleteCategory(this.state.category.id);

		this.CloseForm();
	};

	render() {
		return this.state.loading ||
			this.state.category === {} ||
			this.state.category === undefined ? null : (
			<div className="modal" id="modal-6" data-backdrop="static">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<button
								type="button"
								className="close"
								data-dismiss="modal"
								aria-hidden="true"
								onClick={() => this.CloseForm()}
							>
								&times;
							</button>
							<h4 className="modal-title">
								Delete category {this.state.category.name} ?
							</h4>
						</div>

						<div className="modal-body">
							<div className="row">
								<div className="col-md-12">
									<div className="form-group no-margin">
										<p>Are you sure you want to delete this category?</p>
										{/* <label className="control-label">Name:</label> */}
										{/* <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Name" ref={(input) => this.SiteNameInputText = input} value={this.state.SiteName} onChange={(e) => this.SiteNameChanged(e.target.value)} onKeyDown={(e) => this._handleKeyDown(e.key)} /> */}
									</div>
								</div>
							</div>
						</div>

						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-default"
								data-dismiss="modal"
								onClick={() => this.CloseForm()}
							>
								Close
							</button>
							<button
								type="button"
								className="btn btn-danger"
								data-dismiss="modal"
								onClick={() => this.Delete()}
							>
								Delete
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default DeleteForm;
