import axios from 'axios';
import { ErrorNotification, SuccessNotification } from "../utils/notifications"

export const CheckDiskSpace = async () => {
    axios.defaults.withCredentials = true
    const localtoken = await localStorage.getItem('token');

    return axios.get(`${process.env.REACT_APP_API_URL}/spaceStats`, {
        token: localtoken
    })
    .then(response => {
        


        

        return response.data;
    })
    .catch(error => {
        ErrorNotification("error", error);
        return "ERROR"+error;
    });
}


export const SubmitAnswer = async (Answers, TemplateID, SiteID) => {
    axios.defaults.withCredentials = true
    const localtoken = await localStorage.getItem('token');


//    await axios.post(config.BASE_URL+"SyncAnswers", {Answers: AnswersAUX, TemplateID: this.state.Template.id, SiteID: ParsedToJson[WantedIndex].Site, RefreshToken: await AsyncStorage.getItem('RefreshToken')}).then(res => {


    return axios.post(`${process.env.REACT_APP_API_URL}/SyncAnswers`, {
        Answers: Answers,
        TemplateID: TemplateID,
        SiteID: SiteID,
        token: localtoken
    })
    .then(response => {
        SuccessNotification("success", "Submitted!");
        return response.data;
    })
    .catch(error => {
        ErrorNotification("error","Failed to submit");
        return "ERROR"+error;
    });
}


export const GetAnswerAmount = async (TemplateID, SiteID) => {
    axios.defaults.withCredentials = true
    const localtoken = localStorage.getItem('token');

    return axios.get(`${process.env.REACT_APP_API_URL}/GetAnswerAmount`, {
        params:{
            TemplateID: TemplateID,
            SiteID: SiteID,
            token: localtoken
        }
    })
    .then(response => {
        return response.data;
    })
    .catch(error => {
        return "ERROR"+error;
    });
}


export const RestoreAnswer = async (AnswerID) => {
    axios.defaults.withCredentials = true
    const localtoken = localStorage.getItem('token');

    return axios.post(`${process.env.REACT_APP_API_URL}/RestoreAnswer`, {
        id: AnswerID,
        token: localtoken
    })
    .then(response => {
        return response.data;
    })
    .catch(error => {
        return "ERROR"+error;
    });
}

export const AnswerNoteUpdate = async (AnswerID, AnswerNote) => {
    axios.defaults.withCredentials = true
    const localtoken = localStorage.getItem('token');

    return axios.post(`${process.env.REACT_APP_API_URL}/AnswerNoteUpdate`, {
        id: AnswerID,
        note: AnswerNote,
        token: localtoken
    })
    .then(response => {
        return response.data;
    })
    .catch(error => {
        return "ERROR"+error;
    });
}

export const DeleteAnswer = async (AnswerID) => {
    console.log("answerid: "+AnswerID, "token: "+localStorage.getItem('token'));
    axios.defaults.withCredentials = true
    const localtoken = localStorage.getItem('token');

    return axios.post(`${process.env.REACT_APP_API_URL}/DeleteAnswer`, {
        id: AnswerID,
        token: localtoken
    })
    .then(response => {
        return response.data;
    })
    .catch(error => {
        return "ERROR"+error;
    });
}