//import react
import React from "react";
import {
	ErrorNotification,
	SuccessNotification,
} from "../../utils/notifications";
import {
	setFormType,
	getFormType,
	getSite,
	setSite,
} from "../../utils/sitesForm";
import { getSiteInfo, setSiteUsers } from "../../api/sites";
import Multiselect from "multiselect-react-dropdown";
import Swal from "sweetalert2";

class SiteUsers extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			site: props.site,
			users: props.Users ?? [],
			selectedUsers: [],
		};
	}

	componentDidUpdate = () => {
		if (this.state.loading) this.LoadAll();

		window.jQuery("#modal-6").modal("show", { backdrop: "static" });
	};

	componentDidMount = () => {
		if (this.state.loading) this.LoadAll();
	};

	componentWillUnmount = () => {
		window.jQuery(".modal-backdrop").remove();
	};

	LoadAll = async () => {
		let SiteInfo = await getSiteInfo(this.state.site.id);

		//for each siteinfo user, find it in this.state.users
		let selectedUsers = [];
		SiteInfo.user.forEach((user) => {
			let found = this.state.users.find((u) => u.id === user.id);
			if (found) {
				selectedUsers.push(found);
			}
		});

		this.setState({
			loading: false,
			SiteInfo: SiteInfo,
			selectedUsers: selectedUsers,
		});
	};

	CloseForm = async () => {
		this.props.CloseForm();
	};

	SaveChanges = async () => {
		let resp = await setSiteUsers(this.state.site, this.state.selectedUsers);

		console.log("SaveChanges", resp);

		this.CloseForm();
	};

	onCategoryChanged = (selectedUsers) => {
		this.setState({
			selectedUsers,
		});
	};

	render() {
		if (this.state.loading) {
			return null;
		}

		return (
			<div className="modal" id="modal-6" data-backdrop="static">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<button
								type="button"
								className="close"
								data-dismiss="modal"
								aria-hidden="true"
								onClick={this.CloseForm}
							>
								&times;
							</button>
							<h4 className="modal-title">{this.state.site?.name} users</h4>
						</div>

						<div className="modal-body">
							<div className="row">
								<div className="col-md-12">
									<div className="form-group no-margin">
										{/* <label htmlFor="exampleFormControlInput1" className="control-label">Name:</label>
                                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Name" ref={(input) => this.SiteNameInputText = input} value={this.state.SiteName} onChange={(e) => this.SiteNameChanged(e.target.value)} onKeyDown={(e) => this._handleKeyDown(e.key)} /> */}
										<Multiselect
											options={this.state.users} // Options to display in the dropdown
											selectedValues={this.state.selectedUsers} // Preselected value to persist in dropdown
											onSelect={this.onCategoryChanged} // Function will trigger on select event
											onRemove={this.onCategoryChanged} // Function will trigger on remove event
											displayValue="username" // Property name to display in the dropdown options
											placeholder="Add user"
										/>
									</div>
								</div>
							</div>
						</div>

						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-default"
								data-dismiss="modal"
								onClick={this.CloseForm}
							>
								Close
							</button>
							<button
								type="button"
								className="btn btn-info"
								data-dismiss="modal"
								onClick={this.SaveChanges}
							>
								Save changes
							</button>
						</div>
					</div>
				</div>
			</div>
		);

		return (
			<div
				className={`modal modal-bg`}
				style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
				tabIndex={-1}
			>
				<div
					className="modal-dialog modal-dialog-centered"
					style={{ top: "30%" }}
				>
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" style={{ float: "left" }}>
								{this.state.site?.name} users
							</h5>
							<button
								type="button"
								style={{
									float: "right",
									width: 20,
									height: 20,
									padding: 0,
									margin: 0,
								}}
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
								onClick={this.CloseForm}
							>
								{" "}
								X{" "}
							</button>
						</div>
						<div className="modal-body custom-light-gray">
							<div className="mb-3">
								<Multiselect
									options={this.state.users} // Options to display in the dropdown
									selectedValues={this.state.selectedUsers} // Preselected value to persist in dropdown
									onSelect={this.onCategoryChanged} // Function will trigger on select event
									onRemove={this.onCategoryChanged} // Function will trigger on remove event
									displayValue="username" // Property name to display in the dropdown options
									placeholder="Add user"
								/>
							</div>
							<div style={{ justifyContent: "flex-end", display: "flex" }}>
								<button className="btn btn-secondary" onClick={this.CloseForm}>
									Close
								</button>
								<button
									className="btn btn-primary mx-2"
									onClick={this.SaveChanges}
								>
									Save Changes
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default SiteUsers;
