//import react
import React from "react";
import {
	setFormType,
	getFormType,
	setSite,
	getSite,
} from "../../utils/sitesForm";
import { addSite, deleteSite } from "../../api/sites";

class DeleteForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			site: {},
		};
	}

	componentDidUpdate = () => {
		window.jQuery("#modal-6").modal("show", { backdrop: "static" });

		return;
		var script = document.getElementById("edit-script-modal-sites");

		console.log("Alive!");

		if (script) {
			console.log("remove script!");
			script.remove();
		}
		var scriptx = document.createElement("script");
		scriptx.id = "edit-script-modal-sites";
		scriptx.innerHTML =
			"jQuery('#modal-6').modal('show', {backdrop: 'static'});";
		document.body.appendChild(scriptx);
	};

	componentWillUnmount = () => {
		window.jQuery(".modal-backdrop").remove();
	};

	componentDidMount = () => {
		// console.log("mount delete");
		this.LoadAll();
	};

	LoadAll = async () => {
		var form = await getSite();
		// console.log("load delete form");
		// console.log(form.name);
		this.setState({
			loading: false,
			site: form,
		});

		// console.log(this.state.loading || this.site === {} || this.site === undefined);
		// console.log(this.state.loading);
		// console.log(this.site === {});
		// console.log(this.site === undefined);
	};

	CloseForm = async () => {
		//reset state
		this.setState({
			loading: true,
			site: {},
		});

		this.props.CloseForm();
	};

	Delete = async () => {
		await deleteSite(this.state.site.id);

		this.CloseForm();
	};

	render() {
		if (
			this.state.loading ||
			this.state.site === {} ||
			this.state.site === undefined
		) {
			return null;
		}

		return (
			<div className="modal" id="modal-6" data-backdrop="static">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<button
								type="button"
								className="close"
								data-dismiss="modal"
								aria-hidden="true"
								onClick={this.props.CloseDeleteFormNoChanges}
							>
								&times;
							</button>
							<h4 className="modal-title">
								Delete Site {this.state.site.name} ?
							</h4>
						</div>

						<div className="modal-body">
							<div className="row">
								<div className="col-md-12">
									<div className="form-group no-margin">
										<p>
											Are you sure you want to delete{" "}
											<b>{this.state.site.name}</b>?
										</p>
										{/* <label className="control-label">Name:</label> */}
										{/* <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Name" ref={(input) => this.SiteNameInputText = input} value={this.state.SiteName} onChange={(e) => this.SiteNameChanged(e.target.value)} onKeyDown={(e) => this._handleKeyDown(e.key)} /> */}
									</div>
								</div>
							</div>
						</div>

						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-default"
								data-dismiss="modal"
								onClick={this.props.CloseDeleteFormNoChanges}
							>
								Close
							</button>
							<button
								type="button"
								className="btn btn-danger"
								data-dismiss="modal"
								onClick={this.Delete}
							>
								Delete
							</button>
						</div>
					</div>
				</div>
			</div>
		);

		return (
			<div
				className={`modal modal-bg`}
				style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
				tabIndex={-1}
			>
				<div
					className="modal-dialog modal-dialog-centered"
					style={{ top: "30%" }}
				>
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" style={{ float: "left" }}>
								Delete Site {this.state.site.name} ?
							</h5>
							<button
								type="button"
								style={{
									float: "right",
									width: 20,
									height: 20,
									padding: 0,
									margin: 0,
								}}
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
								onClick={() => this.CloseForm()}
							>
								X
							</button>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-danger py-2 px-4"
								data-bs-dismiss="modal"
								onClick={() => this.Delete()}
							>
								Delete
							</button>
							<button
								type="button"
								className="btn btn-secondary py-2 px-4"
								data-bs-dismiss="modal"
								onClick={() => this.CloseForm()}
							>
								Close
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default DeleteForm;
