//import react
import React from 'react';
import logo from '../../logo.svg';
import '../../App.css';
import { getUser, UpdateProfile } from "../../api/users";
import PasswordForm from "./PasswordForm";
import moment from 'moment';
import {
  useLocation,
  useNavigate,
  useParams
} from "react-router-dom";
import { ErrorNotification, SuccessNotification } from "../../utils/notifications"

function withRouter(Component) {
	function ComponentWithRouterProp(props) {
		let location = useLocation();
		let navigate = useNavigate();
		let params = useParams();
		return (
			<Component {...props} router={{ location, navigate, params }} />
		);
  	}
	return ComponentWithRouterProp;
}

class SettingsPage extends React.Component{
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
            passwordForm: false,
            user: null
		}
	}

	componentDidUpdate = () => {
		if(this.state.loading)
			this.LoadAll();
	}

	componentDidMount = () => {
		if(this.state.loading)
			this.LoadAll();

        var script = document.createElement("script");
        script.id = "TweenMax-sites";
        script.src = "/assets/js/bootstrap.js";
        document.body.appendChild(script);
	}

    UsernameChanged = (username) => {
        this.setState({
            user: {
                ...this.state.user,
                username: username
            }
        })
    }

    PasswordForm = () => {
        this.setState({
            passwordForm: !this.state.passwordForm
        })
    }

    Save = async () => {

        if(this.state.user.username === '' || this.state.user.email === ''){
            ErrorNotification("Username and email are required");
            return;
        }

        await UpdateProfile(this.state?.user?.username, this.state?.user?.email);
        //no need to change pages if error or not. Success or error message will be displayed by the method.
    }

    EmailChanged  = (email) => {
        this.setState({
            user: {
                ...this.state.user,
                email: email
            }
        })
    }

	LoadAll = async () => {
		try {
			var user = await getUser();
            this.setState({
                loading: false,
                user: user
            });
            // console.log("user ->", user)
		} catch (error) {
			console.log("Error.", error);
		}
	}



    render(){
		if(this.state.loading)
            return null

        return (
            <React.Fragment>
                <h2 className="mb-4">Settings</h2>
                <div className="d-flex py-2 px-4 mb-4 me-4">
                    {/* <button key={"NewSite"} type="button" className="btn btn-esip-blue py-2 px-4 mb-4 me-4 custom-letter-spacing"><i className="fas fa-plus"></i>&nbsp;Create Site</button> */}
                </div>
                <div className="p-0" style={{borderBottom: 0, borderLeft: 0, borderRight: 0, height: '85%'}}>
                    <div className="d-flex flex-column justify-content-between align-items-center" style={{height: '100%'}}>
                        <div className="w-100" style={{overflowY: 'auto', justifyContent:'center', }}>
							<div style={{width:'70%', margin: 'auto'}}>
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="InputUsername">Username</label>
                                        <input type="text" className="form-control" value={this.state?.user?.username} onChange={(e) => {this.UsernameChanged(e.target.value)}} id="InputUsername" aria-describedby="username" placeholder="Username"></input>
                                    </div>
                                    <div className="form-group mt-3">
                                        <label htmlFor="InputEmail">Email address</label>
                                        <input type="email" className="form-control" value={this.state?.user?.email} onChange={(e) => {this.EmailChanged(e.target.value)}} id="InputEmail" aria-describedby="email" placeholder="Email"></input>
                                    </div>
                                    {
                                        this.state?.user?.isAdmin ? null :
                                        <div className="form-group mt-3">
                                            <label>Default Site: {this.state?.user?.defaultSite?.name} </label>
                                        </div>
                                    }
                                    <button type="submit" className="btn" onClick={this.Save}>Save</button>
                                    <button type="submit" className="btn" style={{marginLeft: 10}} onClick={this.PasswordForm}>Change password</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.passwordForm ?
                        <PasswordForm CloseForm={this.PasswordForm}/>
                    :null
                }
            </React.Fragment >
        )
    }
}

export default withRouter(SettingsPage);