let FormType = "";
let Site = {};
export const setFormType = (type) => {
  FormType = type;
};

export const getFormType = () => {
  return FormType;
};

export const setSite = (site) => {
  Site = site;
};

export const getSite = () => {
  return Site;
}