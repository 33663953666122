//import react
import React from 'react';
import '../../App.css';
import moment from 'moment';
import {getUser} from "../../utils/loggedUser"
import { ErrorNotification, SuccessNotification } from "../../utils/notifications"
import TemplateSiteAnswers from "../Templates/TemplateSiteAnswers"
import {
  useLocation,
  useNavigate,
  useParams
} from "react-router-dom";
//import GetAnswerAmount
import {GetAnswerAmount} from "../../api/answers"

function withRouter(Component) {
	function ComponentWithRouterProp(props) {
		let location = useLocation();
		let navigate = useNavigate();
		let params = useParams();
		return (
			<Component {...props} router={{ location, navigate, params }} />
		);
  	}
	return ComponentWithRouterProp;
}

class TemplateSites extends React.Component{
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
            template: props.template
		}
	}

	componentDidUpdate = () => {
        if(this.state.loading)
            this.LoadAll();
	}

	componentDidMount = () => {
		if(this.state.loading)
			this.LoadAll();
	}

	LoadAll = async () => {
		try {
			//get user
			var user = getUser();
			// console.log("user", user);

			// if(!user.isAdmin && !user.isSuperUser){
			// 	return;
			// }


			let template = this.state.template;
			
			for(var i = 0; i < template.sites.length; i++){
				var site = template.sites[i];
				var siteAnswers = await GetAnswerAmount(template.id, site.id);
				// console.log("siteAnswers -> ", siteAnswers);
				site.answers = siteAnswers.Amount;
			}


			this.setState({
				loading: false,
				user: user,
				template: template

			});

			

            // console.log("sites->", this.state.template.sites);
			if(this.state.template.sites.length === 1){
				this.setState({
					SelectedSite: this.state.template.sites[0],
					ShowOpenSiteForm: true
				})
			}
            
		} catch (error) {
			console.log("Error.", error);
		}
	}

	CloseForm = async () => {
		this.props.CloseForm();
	}


	OpenSite = (SiteID) => {

        this.SelectedSiteChanged(SiteID);

        var user = this.state.user;

        // console.log("UserSites", UserSites, this.state.SelectedSite);

        //If selectedsite exists in usersites

        if(
            !(user.defaultSite.find(site => site.id === SiteID))
            && (!user.isAdmin && !user.isRoot)
        ){
            ErrorNotification("error","You do not have access to this site.");
            return;
        }


		this.setState({
			ShowOpenSiteForm: true
		});
	}

    SelectedSiteChanged = (SiteID) => {
        var SelectedSite = this.state.template.sites.find(x => x.id === SiteID);
        this.setState({
            SelectedSite: SelectedSite
        });
    }

    CloseSiteForm = () => {
        this.setState({
            ShowOpenSiteForm: false
        });
    }

	componentWillUnmount = () =>{
        window.jQuery('.modal-backdrop').remove();
    }

    render(){
		
		if(this.state.loading){
			return(
				<div>
					<div className="d-flex justify-content-center">
						<div className="spinner-border text-primary" role="status">
							<span className="sr-only"></span>
						</div>
					</div>
					<p style={{textAlign:'center'}}>Loading Template Sites</p>
				</div>
			)
		}
		
		if(this.state.ShowOpenSiteForm){
			return(
				<React.Fragment>
					<TemplateSiteAnswers CloseForm={this.CloseSiteForm} Template={this.state.template} Site={this.state.SelectedSite}/>
				</React.Fragment>
			)
		}
		

        return (
            <React.Fragment>
                <h2 className="mb-4">{this.state.template.name} <i className="entypo-right"></i> Sites</h2>
                <div style={{display:'flex', marginBottom: 5}}>
                    <button className='btn' onClick={()=>{this.CloseForm()}}>
						<i className="entypo-back"></i> Go Back
					</button>
					
					<button key={"Refresh"} type="button" className="btn" style={{marginLeft:10}} onClick={()=>  {this.setState({loading: true});}}>
						<i className="fa fa-refresh"></i> Refresh
                    </button>
                </div>
                <div className="p-0" style={{borderBottom: 0, borderLeft: 0, borderRight: 0, height: '85%'}}>
                    <div className="d-flex flex-column justify-content-between align-items-center" style={{height: '100%'}}>
                        <div className="w-100" style={{overflowY: 'auto'}}>
							<table className='table' key={"table"}>
								<thead>
									<tr>
										<th style={{fontSize: 20, fontWeight: 500}} scope='col'>{"Site Name"}</th>
										{/* <th style={{fontSize: 20, fontWeight: 500}} scope='col'>{"Created At"}</th> */}
										<th style={{fontSize: 20, fontWeight: 500}} scope='col'>{"Answers"}</th>
										<th scope='col' style={{textAlign: 'right', fontSize: 20, fontWeight: 500}} >{"Check Answers"}</th>
									</tr>
								</thead>
								<tbody>
								{
									this.state.template.sites.map((site, index) => {

										// if(!this.state.user?.defaultSite.find(x => x.id === site.id)){
										// 	return;
										// }

										return (
											<tr style={{verticalAlign: 'middle'}} key={"formrow_"+index} onClick={() => this.SelectedSiteChanged(site.id)} onDoubleClick={() => this.OpenSite(site.id)}>
											 {/* <div key={"SiteRow_"+site.id} className={`d-flex align-items-center p-3 custom-border-bottom `} onClick={() => this.SelectedSiteChanged(site.id)} onDoubleClick={() => this.OpenSite()}> */}
												<td><h5 className="mb-0" style={{width: '30%'}}>{site.name}</h5></td>
												{/* <td><h5 className="mb-0" style={{width: '30%'}}>{""+moment(site.createdAt).format('YYYY/MM/DD')}</h5></td> */}
												<td><h5 className="mb-0" >{site.answers}</h5></td>
												<td style={{textAlign:'right'}}>
													<button key={"opensite_"+site.id} type="button" title='Open Site' className="btn btn-esip-blue delete-btn me-2" onClick={() => this.OpenSite(site.id)} style={{ margin: "auto" }}>
														<i className="entypo-right-open-big"></i>
													</button>
													{/* <button key={"editsite_"+site.id} type="button" title='Edit Site' className="btn btn-esip-blue delete-btn me-2" onClick={() => this.EditSite(site.id)}>
														<i className="fas fa-edit"></i>
													</button>
													<button key={"delsite_"+site.id} type="button" title='Delete Site' className="btn btn-esip-red delete-btn" onClick={() => this.DeleteSite(site.id)}> <i className="fas fa-trash"></i></button> */}
												</td>
											{/* </div> */}
											</tr>
										)
									})
								}
								</tbody>
							</table>
                        </div>
                    </div>
                </div>

				
				
            </React.Fragment >
        )
    }
}

export default withRouter(TemplateSites);