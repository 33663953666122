//import react
import React from "react";
import {
	getTemplateAssociatedSites,
	setTemplateSitesAssociation,
	setTemplateCategoriesAssociation,
} from "../../api/templates";
import {
	ErrorNotification,
	SuccessNotification,
} from "../../utils/notifications";
import Multiselect from "multiselect-react-dropdown";
//get user from logged user
import { getUser } from "../../utils/loggedUser";

class SiteAssociator extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			template: this.props.SelectedTemplate,
			loading: true,
			sites: this.props.sites,
			categories: this.props.categories,
			selectedSites: [],
			selectedCategories: [],
			user: null,
			url: "",
		};
	}

	componentWillUnmount = () => {
		window.jQuery(".modal-backdrop").remove();
	};

	componentDidUpdate = () => {
		if (this.state.loading) this.LoadAll();

		var script = document.getElementById("edit-script-modal-sites");

		if (script) {
			console.log("remove script!");
			script.remove();
		}
		var scriptx = document.createElement("script");
		scriptx.id = "edit-script-modal-sites";
		scriptx.innerHTML =
			"jQuery('#modal-6').modal('show', {backdrop: 'static'});";
		document.body.appendChild(scriptx);
	};

	componentDidMount = () => {
		if (this.state.loading) this.LoadAll();
	};

	LoadAll = async () => {
		try {
			const user = await getUser();

			var TemplateSites = await getTemplateAssociatedSites(
				this.state.template.id
			);
			// console.log("templatesites->", TemplateSites);
			var selectedSites = [];

			var selectedCategories = [];

			if (TemplateSites !== null && TemplateSites !== undefined) {
				//for each site in CategoriesAssiciations
				for (var i = 0; i < TemplateSites.sites.length; i++) {
					var SiteId = TemplateSites.sites[i].id;
					// console.log(SiteId);
					//find SiteID in sites
					var foundsite = TemplateSites.sites.find((obj) => {
						return obj.id === SiteId;
					});
					// console.log("found site: ", foundsite);
					selectedSites.push(foundsite);
				}

				//for each category in CategoriesAssiciations
				for (var i = 0; i < TemplateSites.categories.length; i++) {
					var CategoryId = TemplateSites.categories[i].id;
					// console.log(CategoryId);
					//find CategoryID in categories
					var foundcategory = this.state.categories.find((obj) => {
						return obj.id === CategoryId;
					});
					// console.log("found category: ", foundcategory);
					selectedCategories.push(foundcategory);
				}
			}
			// console.log("selectedsites->", selectedSites);
			var sitesDisplayed = [];
			var categoriesDisplayed = [];
			var selectedSitesDisplayed = [];
			var selectedCategoriesDisplayed = [];

			if (user.isSuperUser) {
				sitesDisplayed = TemplateSites.sites.filter((obj) => {
					let find = user.defaultSite.find((o) => {
						return o.id === obj.id;
					});
					return find;
				});

				categoriesDisplayed = this.state.categories.filter((obj) => {
					let find = user.categories.find((o) => {
						return o.id === obj.id;
					});
					return find;
				});

				selectedSitesDisplayed = selectedSites.filter((obj) => {
					let find = user.defaultSite.find((o) => {
						return o.id === obj.id;
					});
					return find;
				});

				selectedCategoriesDisplayed = selectedCategories.filter((obj) => {
					let find = user.categories.find((o) => {
						return o.id === obj.id;
					});
					return find;
				});
			}

			if (user.isAdmin) {
				sitesDisplayed = TemplateSites.sites;
				selectedCategoriesDisplayed = selectedCategories;
				selectedSitesDisplayed = selectedSites;
			}

			/*
            Nota joão: para isto funcionar vamos ter que remover do dos arrays internos o que
            aparece no displayed (porque o componente não devolve o total mas sim o array final após
            se adicionar/remover alguma coisa)
            Então vamos remover do original os que o user pode ver e editar esse array.
            No momento do submit adicionamos o array visivel ao interno para não se perder permissoes.
            Isto pode ser optimizado... mas iria levar algum tempo e neste momento tenho horas. E para a quantidade de dados
            não iria haver grande diferença de performance
            */

			selectedSites = selectedSites.filter((obj) => {
				let find = selectedSitesDisplayed.find((o) => {
					return o.id === obj.id;
				});
				return !find;
			});

			selectedCategories = selectedCategories.filter((obj) => {
				let find = selectedCategoriesDisplayed.find((o) => {
					return o.id === obj.id;
				});
				return !find;
			});

			// console.log("selectedsites->", selectedSites);

			// console.log("user", user);

			var url = window.location.href;
			url = url.split("/home")[0];

			if (
				sitesDisplayed.length === 0 ||
				sitesDisplayed === null ||
				sitesDisplayed === undefined
			) {
				ErrorNotification(
					"Error",
					"There are no sites associated to this template"
				);
				this.CloseForm();
				return;
			}

			url +=
				"/fill?fid=" +
				btoa(this.state.template.id) +
				"&sid=" +
				btoa(sitesDisplayed[0]?.id ?? "");

			this.setState({
				loading: false,
				selectedSites: selectedSites,
				selectedCategories: selectedCategories,
				user: user,
				sitesDisplayed: sitesDisplayed,
				categoriesDisplayed: categoriesDisplayed,
				selectedSitesDisplayed: selectedSitesDisplayed,
				selectedCategoriesDisplayed: selectedCategoriesDisplayed,
				url: url,
			});
		} catch (error) {
			console.log("error loading", error);
		}
	};

	CloseForm = async () => {
		this.props.CloseForm();
	};

	CopyToClipboard = () => {
		navigator.clipboard.writeText(this.state.url);
		SuccessNotification("Url copied to clipboard");
	};

	SiteChanged = (e) => {
		var siteid = e.target.value;
		var site = this.state.sitesDisplayed.find((obj) => {
			return obj.id + "" === siteid + "";
		});
		console.log("SelectedSite", site);
		//change url
		var url = this.state.url;
		url = url.split("sid=")[0];
		url += "sid=" + btoa(site.id);

		this.setState({
			url: url,
		});
	};

	onSiteChanged = (value) => {
		this.setState({
			selectedSitesDisplayed: value,
		});
	};

	render() {
		if (this.state.loading) return null;

		// console.log("rende rmodal share template");
		return (
			<div className="modal fade" id="modal-6" data-backdrop="static">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<button
								type="button"
								className="close"
								data-dismiss="modal"
								aria-hidden="true"
								onClick={this.CloseForm}
							>
								&times;
							</button>
							<h4 className="modal-title">{this.state.template.name}</h4>
						</div>

						<div className="modal-body">
							<h6
								style={{
									textAlign: "center",
									letterSpacing: 1,
									fontWeight: "bold",
								}}
							>
								SITE
							</h6>
							<div style={{ width: "50%", margin: "0 auto", marginTop: 5 }}>
								<select
									className="form-control"
									style={{ borderRadius: 15, textAlign: "center" }}
									onChange={this.SiteChanged}
								>
									{this.state.sitesDisplayed.map((site, index) => {
										return (
											//option
											<option key={"Site_" + index} value={site.id}>
												{site.name}
											</option>
										);
									})}
								</select>
							</div>

							<h6
								style={{
									textAlign: "center",
									marginTop: 25,
									fontWeight: "bold",
									letterSpacing: 1,
								}}
							>
								LINK
							</h6>
							<div
								style={{
									width: "95%",
									display: "table",
									margin: "0 auto",
									marginTop: 5,
									backgroundColor: "white",
									borderRadius: 15,
									textAlign: "center",
									height: 40,
									borderColor: "#d6dbe0",
									borderWidth: 1,
									borderStyle: "solid",
								}}
							>
								<p
									style={{
										verticalAlign: "center",
										lineHeight: "20px",
										height: 45,
										margin: 0,
										marginTop: 10,
									}}
								>
									{this.state.url}
								</p>
							</div>
							<div
								style={{
									width: "100%",
									margin: "0 auto",
									marginTop: 20,
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<button
									key={"formShareCopyUrl"}
									type="button"
									title="Copy Share URL"
									className="btn btn-esip-yellow"
									onClick={() => {
										this.CopyToClipboard();
									}}
								>
									<i className="fa fa-copy"></i> Copy
								</button>
							</div>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-secondary py-2 px-4"
								data-dismiss="modal"
								onClick={this.CloseForm}
							>
								Close
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const styles = {
	default: {
		swatch: {
			padding: "5px",
			background: "#fff",
			borderRadius: "1px",
			boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
			display: "inline-block",
			cursor: "pointer",
		},
		popover: {
			position: "absolute",
			zindex: "2",
		},
	},
};

export default SiteAssociator;
