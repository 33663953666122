//import react
import React from 'react';
import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
  BrowserRouter
} from "react-router-dom";
import LoginPage from './containers/Login';
import HomePage from './containers/HomePage';
import Downloads from './Downloads';
import Privacy from './Privacy';
import { setAccessToken } from "./utils/accessToken"
import {refreshToken} from "./api/getToken"
import {setUser, getUser} from "./utils/loggedUser"
import FormFilling from './FormFilling';
import Sites from './containers/Sites';
import Forms from "./containers/Templates"
import Users from "./containers/Users"
import Settings from "./containers/Settings"
import Categories from "./containers/Categories"
import 'react-notifications-component/dist/theme.css';
import Home from './containers/Home';
 
class App extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      loading: true
    }
    // console.log("APP js constructor!");
  }

  componentDidUpdate = async () => {
    // console.log("APP js componentDidUpdate!");
  }

  componentDidMount = async () => {

    // console.log("APP js componentDidMount!");
    let response = await refreshToken();
    
    if(!response.token || response.token === "" || (response+"").includes("ERROR")){
      
      this.setState({
        loading: false
      });
      return;
    }

    setAccessToken(response.token);
    setUser(response.user);

    this.setState({
      loading: false
    });


  }

  render(){
    return (
      !this.state.loading ?
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={
              <LoginPage/>
            }/>
            <Route exact path="/login" element={
              <LoginPage />
            } />
            <Route path="/home" element={<HomePage/>} >
              <Route path="/home/sites" element={<Sites />} />
              <Route path="/home/forms" element={<Forms />} />
              <Route path="/home/categories" element={<Categories />} />
              <Route path="/home/users" element={<Users />} />
              <Route path="/home/settings" element={<Settings />} />
              <Route path="/home" element={<Home />} />
            </Route>
			      <Route exact path="/downloads" element={
              <Downloads />
            } />
             
            <Route exact path="/privacy" element={
              <Privacy />
            } />
            <Route exact path="/fill" element={
              <FormFilling />
            } />
            {/* <Route
                path="*"
                element={<Navigate to="/" />}
            /> */}
          </Routes>
        </BrowserRouter>
      :
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading</span>
        </div>
      </div>
    );
  }
}

export default App;
