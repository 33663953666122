//import react
import React from 'react';
import { getTemplateAssociatedSites, setTemplateSitesAssociation, setTemplateCategoriesAssociation } from "../../api/templates";
import { ErrorNotification, SuccessNotification } from "../../utils/notifications";
import Multiselect from 'multiselect-react-dropdown';
//get user from logged user
import { getUser } from "../../utils/loggedUser";

class SiteAssociator extends React.Component{
    constructor(props) {
		super(props);
		this.state = {
            template: this.props.SelectedTemplate,
            loading: true,
            sites: this.props.sites,
            categories: this.props.categories,
            selectedSites: [],
            selectedCategories: [],
            user: null
		}
	}

	componentDidUpdate = () => {
		if(this.state.loading)
		    this.LoadAll();	



        window.jQuery('#modal-6').modal('show', {backdrop: 'static'});

        return;


        var script = document.getElementById("edit-script-modal-sites");

        if(script){
            console.log("remove script!")
            script.remove();
        }
        var scriptx = document.createElement("script");
        scriptx.id = "edit-script-modal-sites"
        scriptx.innerHTML = "jQuery('#modal-6').modal('show', {backdrop: 'static'});";
        document.body.appendChild(scriptx);
	}

	componentDidMount = () => {
        if(this.state.loading)
		    this.LoadAll();
	}

    componentWillUnmount = () =>{
        window.jQuery('.modal-backdrop').remove();
    }

	LoadAll = async () => {
        try {

            const user = await getUser();

            var TemplateSites = await getTemplateAssociatedSites(this.state.template.id);
            // console.log("templatesites->", TemplateSites);
            var selectedSites = [];

            var selectedCategories = [];

            if(TemplateSites !== null && TemplateSites !== undefined){
                //for each site in CategoriesAssiciations
                for(var i = 0; i < TemplateSites.sites.length; i++){
                    var SiteId = TemplateSites.sites[i].id;
                    // console.log(SiteId);
                    //find SiteID in sites
                    var foundsite = this.state.sites.find(obj=>{return obj.id === SiteId});
                    // console.log("found site: ", foundsite);
                    selectedSites.push(foundsite);
                }

                //for each category in CategoriesAssiciations
                for(var i = 0; i < TemplateSites.categories.length; i++){
                    var CategoryId = TemplateSites.categories[i].id;
                    // console.log(CategoryId);
                    //find CategoryID in categories
                    var foundcategory = this.state.categories.find(obj=>{return obj.id === CategoryId});
                    // console.log("found category: ", foundcategory);
                    selectedCategories.push(foundcategory);
                }
            }
            // console.log("selectedsites->", selectedSites);
            var sitesDisplayed = [];
            var categoriesDisplayed = [];
            var selectedSitesDisplayed = [];
            var selectedCategoriesDisplayed = [];

            if(user.isSuperUser){
                sitesDisplayed = this.state.sites.filter(obj=>{
                    let find = user.defaultSite.find(o=>{
                        return o.id === obj.id
                    })
                    return find
                });
                
                categoriesDisplayed = this.state.categories.filter(obj=>{
                    let find = user.categories.find(o=>{
                        return o.id === obj.id
                    })
                    return find
                });

                selectedSitesDisplayed = selectedSites.filter(obj=>{
                    let find = user.defaultSite.find(o=>{
                        return o.id === obj.id
                    })
                    return find
                });

                selectedCategoriesDisplayed = selectedCategories.filter(obj=>{
                    let find = user.categories.find(o=>{
                        return o.id === obj.id
                    })
                    return find
                });
            }

            if(user.isAdmin){
                sitesDisplayed = this.state.sites;
                categoriesDisplayed = this.state.categories;
                selectedCategoriesDisplayed = selectedCategories;
                selectedSitesDisplayed = selectedSites;
            }


            /*
            Nota joão: para isto funcionar vamos ter que remover do dos arrays internos o que
            aparece no displayed (porque o componente não devolve o total mas sim o array final após
            se adicionar/remover alguma coisa)
            Então vamos remover do original os que o user pode ver e editar esse array.
            No momento do submit adicionamos o array visivel ao interno para não se perder permissoes.
            Isto pode ser optimizado... mas iria levar algum tempo e neste momento tenho horas. E para a quantidade de dados
            não iria haver grande diferença de performance
            */

            selectedSites = selectedSites.filter(obj=>{
                let find = selectedSitesDisplayed.find(o=>{
                    return o.id === obj.id
                })
                return !find
            })

            selectedCategories = selectedCategories.filter(obj=>{
                let find = selectedCategoriesDisplayed.find(o=>{
                    return o.id === obj.id
                })
                return !find
            })


            // console.log("selectedsites->", selectedSites);


            // console.log("user", user);
            this.setState({
                loading: false,
                selectedSites: selectedSites,
                selectedCategories: selectedCategories,
                user: user,
                sitesDisplayed: sitesDisplayed,
                categoriesDisplayed: categoriesDisplayed,
                selectedSitesDisplayed: selectedSitesDisplayed,
                selectedCategoriesDisplayed: selectedCategoriesDisplayed
            });
        } catch (error) {
            console.log("error loading", error);
        }
	}

    CloseForm = async () => {
        //reset state
        this.setState({
            loading: true,
            category: {}
        });

        this.props.CloseForm();
    }

    CloseFormNoChanges = async () => {
        this.props.CloseFormNoChanges();
    }

    onSiteChanged = (value) => {
        this.setState({
            selectedSitesDisplayed: value
        })
    }

    onCategoryChanged = (value) => {
        this.setState({
            selectedCategoriesDisplayed: value
        })
    }


    Save = async () => {
        try {


            //aqui está adicionamos então visivel ao interno
            let selectedsites = this.state.selectedSites.concat(this.state.selectedSitesDisplayed);
            let selectecategories = this.state.selectedCategories.concat(this.state.selectedCategoriesDisplayed);
            
            // console.log("selectedsites->", selectedsites);
            // console.log("selectecategories->", selectecategories);

            var AssociationRes = await setTemplateSitesAssociation(this.state.template.id, selectedsites);
            var AssociationCatRes = await setTemplateCategoriesAssociation(this.state.template.id, selectecategories);
            if(AssociationRes !== null){
                SuccessNotification("Template sites saved successfully");
                try {

                    window.jQuery('#modal-6').modal('hide');
                    // var scriptx = document.createElement("script");
                    // scriptx.id = "closemodal"
                    // scriptx.innerHTML = "jQuery('#modal-6').modal('hide');";
                    // document.body.appendChild(scriptx);
    
                    // document.findElementById("closemodal").remove();
                } catch (error) {
                    
                }
                this.CloseForm();
            }
        } catch (error) {
            ErrorNotification("Error saving template sites");
        }
    }

    render(){
        if(this.state.loading)
            return null;

        return (
            <div className="modal fade" id="modal-6" data-backdrop="static">
                <div className="modal-dialog">
                    <div className="modal-content">

                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={this.CloseFormNoChanges}></button>
                            <h4 className="modal-title">Associations on &lt; {this.state.template.name} &gt;</h4>
                        </div>
                        
                        <div className="modal-body">
                            <h6>Sites</h6>
                            <Multiselect
                                options={this.state.sitesDisplayed} // Options to display in the dropdown
                                selectedValues={this.state.selectedSitesDisplayed} // Preselected value to persist in dropdown
                                onSelect={this.onSiteChanged} // Function will trigger on select event
                                onRemove={this.onSiteChanged} // Function will trigger on remove event
                                displayValue="name" // Property name to display in the dropdown options
                                placeholder="Select a site"
                            />
                            <h6 style={{marginTop: 15}}>Categories</h6>
                            <Multiselect
                                options={this.state.categoriesDisplayed} // Options to display in the dropdown
                                selectedValues={this.state.selectedCategoriesDisplayed} // Preselected value to persist in dropdown
                                onSelect={this.onCategoryChanged} // Function will trigger on select event
                                onRemove={this.onCategoryChanged} // Function will trigger on remove event
                                displayValue="name" // Property name to display in the dropdown options
                                placeholder="Select a category"
                                />
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary py-2 px-4" data-dismiss="modal" onClick={this.CloseFormNoChanges}>Close</button>
                            <button type="button" className="btn btn-esip-blue py-2 px-4" onClick={()=>this.Save()}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const styles = ({
    'default': {
        swatch: {
            padding: '5px',
            background: '#fff',
            borderRadius: '1px',
            boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
            display: 'inline-block',
            cursor: 'pointer',
        },
      popover: {
        position: 'absolute',
        zindex: '2',
      }
    },
  });

export default (SiteAssociator);