//import react
import React from "react";
import {
	getTemplateAnswers,
	getAnswersCSV,
	getAnswersZIP,
	listAnswersCSVV2,
	listAnswersZIPV2

} from "../../api/templates";
import {
	ErrorNotification,
	SuccessNotification,
} from "../../utils/notifications";
import Moment from "moment";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import * as saveAs from "file-saver";
import {
	RestoreAnswer,
	DeleteAnswer,
	AnswerNoteUpdate,
} from "../../api/answers";

class ListFormAnswers extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			site: this.props.Site,
			category: this.props.Category,
			template: this.props.Template,
			answers: [],
			selectedAnswers: [],
			SelectedForm: null,
			ShowFormAnswers: false,
			images: [],
			showCarousel: false,
			showDeleted: false,
		};
	}

	componentDidUpdate = () => {
		if (this.state.loading) this.LoadAll();

		var script = document.getElementById("edit-script-modal-sites");

		if (script) {
			console.log("remove script!");
			script.remove();
		}
		var scriptx = document.createElement("script");
		scriptx.id = "edit-script-modal-sites";
		scriptx.innerHTML =
			"jQuery('#modal-6').modal('show', {backdrop: 'static'}); jQuery('#modal-6').css('max-height', jQuery(window).height());";
		document.body.appendChild(scriptx);
	};

	componentDidMount = () => {
		if (this.state.loading) this.LoadAll();
	};

	LoadAll = async () => {
		try {
			let answers = await getTemplateAnswers(
				this.state.template.id,
				this.state.site.id
			);

			// console.log("->",answers);

			this.setState({
				loading: false,
				answers: answers,
			});
		} catch (error) {
			console.log("Error", error);
		}
	};

	CloseForm = async () => {
		this.setState({
			ShowForm: false,
			loading: true,
		});
		this.LoadAll();
	};

	CheckboxClicked = async (id) => {
		// if this.state.answers contains id, remove it, else add it
		if (this.state.selectedAnswers.includes(id)) {
			this.setState({
				selectedAnswers: this.state.selectedAnswers.filter(
					(item) => item !== id
				),
			});
		} else {
			this.setState({
				selectedAnswers: [...this.state.selectedAnswers, id],
			});
		}
	};

	findTemplateByID = (templateid) => {};

	GetCSV = async () => {
		var filename = await getAnswersCSV(
			this.state.selectedAnswers,
			this.state.template.id
		);

		var csvlink = `${process.env.REACT_APP_API_URL}/Templates/Answers/${filename}`;

		window.open(csvlink, "_blank");
		this.LoadAll();
		// let file = new Blob([csv], { type: 'text/csv' });

		// saveAs(file);
	};

	GetZIP = async () => {
		var filename = await getAnswersZIP(
			this.state.selectedAnswers,
			this.state.template.id
		);

		var zip = `${process.env.REACT_APP_API_URL}/Templates/Answers/${filename}`;

		if (filename.includes(".zip")) {
			window.open(zip, "_blank");
			this.LoadAll();
		}
	};

	GetCSVV2 = async () => {

		this.setState({
			loading: true
		});

        let resp = await listAnswersCSVV2(this.state.selectedAnswers, this.state.template.id);

		console.log("TemplateSite Answers GetCSVV2", resp);
		 
		var csvlink = `${process.env.REACT_APP_API_URL}/Templates/Answers/${resp.fileName}`;

		window.open(csvlink, "_blank");
		this.LoadAll();
		   
		this.setState({
			loading: false
			
		});
	};

	GetZIPV2 = async () => {
		this.setState({
			loading: true
		});
        let resp = await listAnswersZIPV2(this.state.selectedAnswers, this.state.template.id);

	 

		var zip = `${process.env.REACT_APP_API_URL}/Templates/Answers/${resp}`;

		if (resp.includes(".zip")) {
			window.open(zip, "_blank");
			this.LoadAll();
		}
		this.setState({
			loading: false
		});
	};


	DownloadSingle = async (id) => {
		var list = [id];
		var filename = await getAnswersCSV(list, this.state.template.id);

		var csvlink = `${process.env.REACT_APP_API_URL}/Templates/Answers/${filename}`;

		window.open(csvlink, "_blank");
		this.LoadAll();

		// let file = new Blob([csv], { type: 'text/csv' });

		// saveAs(file);
	};

	SelectAll = () => {
		var selectedList = this.state.selectedAnswers;
		var list = [];
		for (var i = 0; i < this.state.answers.length; i++) {
			if (this.state.answers[i].deletedBy && this.state.showDeleted) {
				continue;
			}

			if (
				!this.state.answers[i].Downloaded ||
				(selectedList !== null &&
					selectedList !== undefined &&
					selectedList.length > 0)
			) {
				list.push(this.state.answers[i].id);
			}
		}
		this.setState({
			selectedAnswers: list,
		});
	};

	RemoveSelected = async () => {
		this.setState({ selectedAnswers: [] });
	};

	CheckPhotos = async (AnswerID) => {
		//when we create the csv photos are generated!
		var list = [AnswerID];
		// var csv = await getAnswersCSV(list, this.state.template.id);
		await getAnswersCSV(list, this.state.template.id);

		var photourl = `${process.env.REACT_APP_API_URL}/Templates/Answers/answertemplate_${AnswerID}_photo`;

		var images = [];

		for (var i = 1; i < 10; i++) {
			var url = `${photourl}_${i}.jpg`;

			//check if image exists
			var response = await fetch(url);
			if (response.status === 200) {
				images.push(url);
			} else {
				break;
			}
		}

		if (images.length === 0) {
			ErrorNotification("No photos were found");
			return;
		}

		this.setState({
			showCarousel: true,
			images: images,
		});
	};

	CloseCarousel = () => {
		this.setState({
			showCarousel: false,
		});
	};

	render() {
		if (this.state.loading) return null;

		return (
			<React.Fragment>
				<h2 className="mb-4">
					{this.state.site.name + " "}
					<i className="entypo-right"></i>
					{" " + this.state.category.name + " "}
					<i className="entypo-right"></i>
					{" " + this.state.template.name}
				</h2>
				<div style={{ display: "flex", minHeight: 35, marginBottom: 25 }}>
					<button
						key={"GoBack"}
						type="button"
						className="btn"
						onClick={this.props.CloseForm}
						style={{ marginLeft: 5 }}
					>
						<i className="entypo-back"></i> Go Back
					</button>
					<button
						key={"SelectAll"}
						type="button"
						className="btn"
						onClick={this.SelectAll}
						style={{ marginLeft: 5 }}
					>
						{this.state.selectedAnswers.length > 0
							? "Select All"
							: "Select not yet downloaded"}
					</button>
					<button
						key={"Deselect"}
						type="button"
						className="btn"
						onClick={this.RemoveSelected}
						style={{ marginLeft: 5 }}
					>
						Remove selection
					</button>
					<button
						key={"Refresh"}
						type="button"
						className="btn"
						onClick={() => {
							this.setState({ loading: true });
						}}
						style={{ marginLeft: 5 }}
					>
						<i className="fa fa-refresh"></i> Refresh
					</button>
					<button
						key={"ShowDeleted"}
						type="button"
						className="btn"
						onClick={() => {
							this.setState({ showDeleted: !this.state.showDeleted });
						}}
						style={{ marginLeft: 5 }}
					>
						{this.state.showDeleted ? "Show Deleted" : "Hide Deleted"}
					</button>
					<button
						type="button"
						className={`btn`}
						onClick={() => this.GetZIPV2()}
						disabled={this.state.selectedAnswers.length === 0}
						style={{ marginLeft: 5 }}
					>
						<i className="fas fa-file-archive"></i>&nbsp;Export With Photos
					</button>
					<button
						type="button"
						className={`btn`}
						onClick={() => this.GetCSVV2()}
						disabled={this.state.selectedAnswers.length === 0}
						style={{ marginLeft: 5 }}
					>
						<i className="fas fa-file-csv"></i>&nbsp;Export CSV
					</button>
				</div>
				<div className="p-0">
					<div className="d-flex flex-column justify-content-between align-items-center">
						<div className="w-100">
							<table className="table" key={"table"}>
								<thead>
									<tr>
										<th style={{ fontSize: 20, fontWeight: 500 }} scope="col">
											{""}
										</th>
										<th style={{ fontSize: 20, fontWeight: 500 }} scope="col">
											{"User"}
										</th>
										<th style={{ fontSize: 20, fontWeight: 500 }} scope="col">
											{"Date"}
										</th>
										<th style={{ fontSize: 20, fontWeight: 500 }} scope="col">
											{"Notes"}
										</th>
										<th
											scope="col"
											style={{
												textAlign: "right",
												fontSize: 20,
												fontWeight: 500,
											}}
										>
											{"Actions"}
										</th>
									</tr>
								</thead>
								<tbody>
									{this.state.answers.map((answer, index) => {
										if (answer.deletedBy && this.state.showDeleted) {
											return;
										}
										return (
											<tr
												style={{
													verticalAlign: "middle",
													minHeight: 75,
													height: 75,
												}}
												key={"formrow_" + index}
											>
												<td>
													<input
														className="mb-0"
														style={{ marginRight: 20, width: 25, height: 25 }}
														key={"check_" + answer.id}
														type={"checkbox"}
														onChange={() => this.CheckboxClicked(answer.id)}
														checked={this.state.selectedAnswers.includes(
															answer.id
														)}
													></input>
												</td>
												<td>
													<h5 className="mb-0">
														{answer.user.email}&nbsp;&nbsp;&nbsp;&nbsp;
													</h5>
												</td>
												<td>
													<h5 className="mb-0">
														{Moment(answer.createdAt).format(
															"yyyy-MM-DD | HH:mm"
														)}
													</h5>
												</td>
												<td>
													<input
														className="form-control"
														type={"text"}
														key={"Answer_" + answer.id}
														multiple
														placeholder="Add a note here"
														value={answer.notes}
														onChange={(e) => {
															var answers = this.state.answers;
															answers[index].notes = e.target.value;
															this.setState({ answers: answers });
														}}
														onBlur={async (e) => {
															await AnswerNoteUpdate(answer.id, e.target.value);
														}}
													></input>
												</td>
												<td style={{ textAlign: "right" }}>
													{!answer.deletedBy ? (
														<button
															key={"Delete"}
															type="button"
															className="btn btn-danger"
															onClick={async () => {
																await DeleteAnswer(answer.id);
																this.LoadAll();
															}}
															style={{ width: 80 }}
														>
															Delete
														</button>
													) : (
														<button
															key={"Restore"}
															type="button"
															className="btn btn-success"
															onClick={async () => {
																await RestoreAnswer(answer.id);
																this.LoadAll();
															}}
															style={{ width: 80 }}
														>
															Restore
														</button>
													)}
													<button
														key={"photocarousel_" + answer.id}
														type="button"
														className="btn btn-esip-blue me-2"
														style={{ marginLeft: 5 }}
														onClick={() => this.CheckPhotos(answer.id)}
														disabled={!answer.HasPhoto}
													>
														<i className="entypo-picture"></i>
													</button>
													<button
														key={"editcategory_" + answer.id}
														type="button"
														className="btn btn-esip-blue me-2"
														style={{ marginLeft: 5 }}
														onClick={() => this.DownloadSingle(answer.id)}
													>
														<i className="entypo-download"></i>
													</button>
													<p
														style={{
															fontSize: 15,
															fontWeight: "bold",
															marginTop: 5,
														}}
													>
														{answer.Downloaded ? "Downloaded" : ""}
													</p>
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					</div>
				</div>
				{this.state.showCarousel ? (
					<div
						className={`modal fade`}
						id="modal-6"
						tabIndex={-1}
						data-backdrop="static"
					>
						<div className="modal-dialog">
							<div className="modal-content">
								<div className="modal-header">
									<button
										type="button"
										className="close"
										data-dismiss="modal"
										aria-hidden="true"
										onClick={() => this.CloseCarousel()}
									>
										&times;
									</button>
									<h4 className="modal-title">Photos</h4>
								</div>
								<div className="modal-body">
									<Carousel showArrows infiniteLoop autoPlay emulateTouch>
										{this.state.images.map((image, index) => {
											return (
												<div>
													<img src={image} alt="occorence" />
												</div>
											);
										})}
									</Carousel>
								</div>
								<div className="modal-footer justify-content-center">
									<button
										type="button"
										className="btn btn-secondary py-2 px-4"
										data-dismiss="modal"
										onClick={() => this.CloseCarousel()}
									>
										Close
									</button>
								</div>
							</div>
						</div>
					</div>
				) : null}
			</React.Fragment>
		);
	}
}

export default ListFormAnswers;
